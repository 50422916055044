import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Add, Close, Edit } from "@mui/icons-material";
import { UserInfo } from "../../context";
import moment from "moment";

const RentalUnit = () => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState(null);
  const [addRentalDialog, setAddRentalDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [rentalUnitList, setRentalList] = useState({ data: [], ready: false });
  const [selectedTime, setSelectedTime] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getRentalUnitList = () => {
    axios
      .get("user/admin/get/rental-unit")
      .then((response) => {
        const data = response.data;
        setRentalList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    getRentalUnitList();
  }, []);

  const handleNewRentalUnit = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append(
      "time_retrieval",
      moment(`2023-09-21 ${selectedTime}`).format("HH:mm:ss")
    );

    setIsProcess(true);
    axios
      .post("user/admin/create/new-rental-unit", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully added new rental unit");
          setSeverity("success");
          setOpenSnackbar(true);
          setAddRentalDialog(false);
          setIsProcess(false);
          getRentalUnitList();
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  const handleEditRentalUnit = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", selectedData !== null && selectedData.id);
    formdata.append(
      "time_retrieval",
      moment(`2023-09-21 ${selectedTime}`).format("HH:mm:ss")
    );

    setIsProcess(true);
    axios
      .post("user/admin/update/rental-unit", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setSelectedTime(null);
          setMessage("Successfully updated rental unit");
          setSeverity("success");
          setOpenSnackbar(true);
          setEditDialog(false);
          setIsProcess(false);
          getRentalUnitList();
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Add Rental Unit">
            <IconButton
              onClick={() => {
                setAddRentalDialog(true);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Item Code</b>
              </TableCell>
              <TableCell align="center">
                <b>Item Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Item Description</b>
              </TableCell>
              <TableCell align="center">
                <b>Time Retrieval</b>
              </TableCell>
              <TableCell align="center">
                <b>Cost Per Day</b>
              </TableCell>
              <TableCell align="center">
                <b>Available</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rentalUnitList.ready && rentalUnitList.data.length > 0 ? (
              rentalUnitList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.item_code}</TableCell>
                  <TableCell>{data.item_name}</TableCell>
                  <TableCell align="right">{data.item_description}</TableCell>
                  <TableCell align="right">
                    {moment(`2023-10-16 ${data.time_retrieval}`).format("LT")}
                  </TableCell>
                  <TableCell align="right">&#8369;{data.item_cost}</TableCell>
                  <TableCell align="right">{data.available}</TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Edit Unit">
                        <IconButton
                          onClick={() => {
                            setSelectedTime(
                              moment(
                                `2023-10-16 ${data.time_retrieval}`
                              ).format("HH:mm")
                            );
                            setSelectedData(data);
                            setEditDialog(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8} style={{ color: "red" }}>
                  No rental unit added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={rentalUnitList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* ADD NEW RENTAL UNIT */}
      <Dialog
        open={addRentalDialog}
        onClose={() => setAddRentalDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>
                Add Rental Unit
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddRentalDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <form onSubmit={handleNewRentalUnit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 1,
                }}
              >
                <TextField
                  InputProps={{
                    disableunderline: "true",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Item Code"
                  fullWidth
                  name="item_code"
                  required
                  size="small"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 1,
                }}
              >
                <TextField
                  InputProps={{
                    disableunderline: "true",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Item Name"
                  fullWidth
                  name="item_name"
                  required
                  size="small"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 1,
                }}
              >
                <TextField
                  InputProps={{
                    disableunderline: "true",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Item Description (Optional)"
                  fullWidth
                  name="item_description"
                  size="small"
                  multiline
                  minRows={3}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 1,
                }}
              >
                <TextField
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 99999999999,
                      step: 0.01,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Cost Per Day"
                  fullWidth
                  name="item_cost"
                  required
                  type="number"
                  size="small"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  label="Time Period"
                  type="time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5-minute intervals
                  }}
                  fullWidth
                  size="small"
                  required
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    // size="small"
                    sx={{
                      //   textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      paddingX: 2,
                      backgroundColor: isProcess ? "#e5e5e5" : "#d65d46",
                    }}
                    type="submit"
                    disabled={isProcess}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>

      {/* EDIT RENTAL UNIT */}
      <Dialog
        open={editDialog}
        onClose={() => setEditDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Edit Station</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setEditDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {selectedData !== null && (
              <form onSubmit={handleEditRentalUnit}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    InputProps={{
                      disableunderline: "true",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Item Code"
                    fullWidth
                    name="item_code"
                    required
                    size="small"
                    defaultValue={selectedData.item_code}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    InputProps={{
                      disableunderline: "true",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Item Name"
                    fullWidth
                    name="item_name"
                    required
                    size="small"
                    defaultValue={selectedData.item_name}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    InputProps={{
                      disableunderline: "true",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Item Description (Optional)"
                    fullWidth
                    name="item_description"
                    size="small"
                    multiline
                    minRows={3}
                    defaultValue={selectedData.item_description}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 99999999999,
                        step: 0.01,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Cost Per Day"
                    fullWidth
                    name="item_cost"
                    required
                    type="number"
                    size="small"
                    defaultValue={selectedData.item_cost}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    label="Time Period"
                    type="time"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5-minute intervals
                    }}
                    fullWidth
                    size="small"
                    required
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor: isProcess ? "#e5e5e5" : "#d65d46",
                      }}
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default RentalUnit;
