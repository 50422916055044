import { Close, Search, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  TablePagination,
  List,
  ListItemText,
  Divider,
  ListItemButton,
  Dialog,
} from "@mui/material";
import React, { Fragment, useContext } from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import axios from "axios";
import { UserInfo } from "../context";
import { useEffect } from "react";
import BranchDetails from "./branch/BranchDetails";

const ProprietorPage = () => {
  const [search, setSearch] = useState("");
  const [branchList, setBranchList] = useState({ data: [], ready: false });
  const {
    setIsProcess,
    isProcess,
    setMessage,
    setSeverity,
    setOpenSnackbar,
    userData,
  } = useContext(UserInfo);
  const [passwordShow, setPasswordShow] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addBranchDiag, setAddBranchDiag] = useState(false);
  const [inputedType, setInputedType] = useState("");
  const [inputedCity, setInputedCity] = useState("");

  const foodCat = [
    { value: "restaurant", name: "Restaurant" },
    { value: "caterers", name: "Caterers" },
    { value: "homebasekit", name: "Home Based Kitchen" },
    { value: "streetfood", name: "Street Food" },
    { value: "snacksweets", name: "Snacks & Sweets" },
    { value: "drinks", name: "Drinks" },
    { value: "bakery", name: "Bakery" },
    { value: "convenience", name: "Convenience" },
    { value: "groceries", name: "Groceries" },
  ];

  const nonfoodCat = [
    { value: "beauty", name: "Beauty" },
    { value: "electronics", name: "Electronics" },
    { value: "fashion", name: "Fashion" },
    { value: "flowers", name: "Flowers" },
    { value: "games", name: "Games" },
    { value: "healthwellness", name: "Health & Wellness" },
    { value: "homegifts", name: "Home & Gifts" },
    { value: "adults", name: "Just for Adults" },
    { value: "motherbaby", name: "Mother & baby" },
    { value: "partysupplies", name: "Party Supplies" },
    { value: "pets", name: "Pets" },
    { value: "pharmacy", name: "Pharmacy" },
    { value: "sportslifestyle", name: "Sports & Lifestyle" },
    { value: "stationerybooks", name: "Stationery & Books" },
    { value: "supermarket", name: "Supermarket" },
    { value: "vehicles", name: "Vehicles" },
  ];

  const cityList = [
    { value: "Davao City", name: "Davao City" },
    { value: "Tagum City", name: "Tagum City" },
    { value: "Panabo City", name: "Panabo City" },
    { value: "Butuan City", name: "Butuan City" },
  ];

  const davaoBrgy = [
    { value: "Acacia", name: "Acacia" },
    { value: "Agdao", name: "Agdao" },
    { value: "Alambre", name: "Alambre" },
    { value: "Alejandra Navarro", name: "Alejandra Navarro" },
    { value: "Alfonso Angliongto Sr.", name: "Alfonso Angliongto Sr." },
    { value: "Angalan", name: "Angalan" },
    { value: "Atan-awe", name: "Atan-awe" },
    { value: "Baganihan", name: "Baganihan" },
    { value: "Bago Aplaya", name: "Bago Aplaya" },
    { value: "Bago Gallera", name: "Bago Gallera" },
    { value: "Bago Oshiro", name: "Bago Oshiro" },
    { value: "Baguio", name: "Baguio" },
    { value: "Balengaeng", name: "Balengaeng" },
    { value: "Baliok", name: "Baliok" },
    { value: "Bangkas Heights", name: "Bangkas Heights" },
    { value: "Bantol", name: "Bantol" },
    { value: "Baracatan", name: "Baracatan" },
    { value: "Barangay 10-A", name: "Barangay 10-A" },
    { value: "Barangay 11-B", name: "Barangay 11-B" },
    { value: "Barangay 12-B", name: "Barangay 12-B" },
    { value: "Barangay 13-B", name: "Barangay 13-B" },
    { value: "Barangay 14-B", name: "Barangay 14-B" },
    { value: "Barangay 15-B", name: "Barangay 15-B" },
    { value: "Barangay 16-B", name: "Barangay 16-B" },
    { value: "Barangay 17-B", name: "Barangay 17-B" },
    { value: "Barangay 18-B", name: "Barangay 18-B" },
    { value: "Barangay 19-B", name: "Barangay 19-B" },
    { value: "Barangay 1-A", name: "Barangay 1-A" },
    { value: "Barangay 20-B", name: "Barangay 20-B" },
    { value: "Barangay 21-C", name: "Barangay 21-C" },
    { value: "Barangay 22-C", name: "Barangay 22-C" },
    { value: "Barangay 23-C", name: "Barangay 23-C" },
    { value: "Barangay 24-C", name: "Barangay 24-C" },
    { value: "Barangay 25-C", name: "Barangay 25-C" },
    { value: "Barangay 26-C", name: "Barangay 26-C" },
    { value: "Barangay 27-C", name: "Barangay 27-C" },
    { value: "Barangay 28-C", name: "Barangay 28-C" },
    { value: "Barangay 29-C", name: "Barangay 29-C" },
    { value: "Barangay 2-A", name: "Barangay 2-A" },
    { value: "Barangay 30-C", name: "Barangay 30-C" },
    { value: "Barangay 31-D", name: "Barangay 31-D" },
    { value: "Barangay 32-D", name: "Barangay 32-D" },
    { value: "Barangay 33-D", name: "Barangay 33-D" },
    { value: "Barangay 34-D", name: "Barangay 34-D" },
    { value: "Barangay 35-D", name: "Barangay 35-D" },
    { value: "Barangay 36-D", name: "Barangay 36-D" },
    { value: "Barangay 37-D", name: "Barangay 37-D" },
    { value: "Barangay 38-D", name: "Barangay 38-D" },
    { value: "Barangay 39-D", name: "Barangay 39-D" },
    { value: "Barangay 3-A", name: "Barangay 3-A" },
    { value: "Barangay 40-D", name: "Barangay 40-D" },
    { value: "Barangay 4-A", name: "Barangay 4-A" },
    { value: "Barangay 5-A", name: "Barangay 5-A" },
    { value: "Barangay 6-A", name: "Barangay 6-A" },
    { value: "Barangay 7-A", name: "Barangay 7-A" },
    { value: "Barangay 8-A", name: "Barangay 8-A" },
    { value: "Barangay 9-A", name: "Barangay 9-A" },
    { value: "Bato", name: "Bato" },
    { value: "Bayabas", name: "Bayabas" },
    { value: "Biao Escuela", name: "Biao Escuela" },
    { value: "Biao Guianga", name: "Biao Guianga" },
    { value: "Biao Joaquin", name: "Biao Joaquin" },
    { value: "Binugao", name: "Binugao" },
    { value: "Bucana", name: "Bucana" },
    { value: "Buda", name: "Buda" },
    { value: "Buhangin", name: "Buhangin" },
    { value: "Bunawan", name: "Bunawan" },
    { value: "Cabantian", name: "Cabantian" },
    { value: "Cadalian", name: "Cadalian" },
    { value: "Calinan", name: "Calinan" },
    { value: "Callawa", name: "Callawa" },
    { value: "Camansi", name: "Camansi" },
    { value: "Carmen", name: "Carmen" },
    { value: "Catalunan Grande", name: "Catalunan Grande" },
    { value: "Catalunan Pequeño", name: "Catalunan Pequeño" },
    { value: "Catigan", name: "Catigan" },
    { value: "Cawayan", name: "Cawayan" },
    { value: "Centro", name: "Centro" },
    { value: "Colosas", name: "Colosas" },
    { value: "Communal", name: "Communal" },
    { value: "Crossing Bayabas", name: "Crossing Bayabas" },
    { value: "Dacudao", name: "Dacudao" },
    { value: "Dalag", name: "Dalag" },
    { value: "Dalagdag", name: "Dalagdag" },
    { value: "Daliao", name: "Daliao" },
    { value: "Daliaon Plantation", name: "Daliaon Plantation" },
    { value: "Datu Salumay", name: "Datu Salumay" },
    { value: "Dominga", name: "Dominga" },
    { value: "Dumoy", name: "Dumoy" },
    { value: "Eden", name: "Eden" },
    { value: "Fatima", name: "Fatima" },
    { value: "Gatungan", name: "Gatungan" },
    { value: "Gov. Paciano Bangoy", name: "Gov. Paciano Bangoy" },
    { value: "Gov. Vicente Duterte", name: "Gov. Vicente Duterte" },
    { value: "Gumalang", name: "Gumalang" },
    { value: "Gumitan", name: "Gumitan" },
    { value: "Ilang", name: "Ilang" },
    { value: "Inayangan", name: "Inayangan" },
    { value: "Indangan", name: "Indangan" },
    { value: "Kap. Tomas Monteverde, Sr.", name: "Kap. Tomas Monteverde, Sr." },
    { value: "Kilate", name: "Kilate" },
    { value: "Lacson", name: "Lacson" },
    { value: "Lamanan", name: "Lamanan" },
    { value: "Lampianao", name: "Lampianao" },
    { value: "Langub", name: "Langub" },
    { value: "Lapu-lapu", name: "Lapu-lapu" },
    { value: "Leon Garcia, Sr.", name: "Leon Garcia, Sr." },
    { value: "Lizada", name: "Lizada" },
    { value: "Los Amigos", name: "Los Amigos" },
    { value: "Lubogan", name: "Lubogan" },
    { value: "Lumiad", name: "Lumiad" },
    { value: "Ma-a", name: "Ma-a" },
    { value: "Mabuhay", name: "Mabuhay" },
    { value: "Magsaysay", name: "Magsaysay" },
    { value: "Magtuod", name: "Magtuod" },
    { value: "Mahayag", name: "Mahayag" },
    { value: "Malabog", name: "Malabog" },
    { value: "Malagos", name: "Malagos" },
    { value: "Malamba", name: "Malamba" },
    { value: "Manambulan", name: "Manambulan" },
    { value: "Mandug", name: "Mandug" },
    { value: "Manuel Guianga", name: "Manuel Guianga" },
    { value: "Mapula", name: "Mapula" },
    { value: "Marapangi", name: "Marapangi" },
    { value: "Marilog", name: "Marilog" },
    { value: "Matina Aplaya", name: "Matina Aplaya" },
    { value: "Matina Biao", name: "Matina Biao" },
    { value: "Matina Crossing", name: "Matina Crossing" },
    { value: "Matina Pangi", name: "Matina Pangi" },
    { value: "Megkawayan", name: "Megkawayan" },
    { value: "Mintal", name: "Mintal" },
    { value: "Mudiang", name: "Mudiang" },
    { value: "Mulig", name: "Mulig" },
    { value: "New Carmen", name: "New Carmen" },
    { value: "New Valencia", name: "New Valencia" },
    { value: "Pampanga", name: "Pampanga" },
    { value: "Panacan", name: "Panacan" },
    { value: "Panalum", name: "Panalum" },
    { value: "Pandaitan", name: "Pandaitan" },
    { value: "Pangyan", name: "Pangyan" },
    { value: "Paquibato", name: "Paquibato" },
    { value: "Paradise Embak", name: "Paradise Embak" },
    { value: "Rafael Castillo", name: "Rafael Castillo" },
    { value: "Riverside", name: "Riverside" },
    { value: "Salapawan", name: "Salapawan" },
    { value: "Salaysay", name: "Salaysay" },
    { value: "Saloy", name: "Saloy" },
    { value: "San Antonio", name: "San Antonio" },
    { value: "San Isidro", name: "San Isidro" },
    { value: "Santo Niño", name: "Santo Niño" },
    { value: "Sasa", name: "Sasa" },
    { value: "Sibulan", name: "Sibulan" },
    { value: "Sirawan", name: "Sirawan" },
    { value: "Sirib", name: "Sirib" },
    { value: "Suawan", name: "Suawan" },
    { value: "Subasta", name: "Subasta" },
    { value: "Sumimao", name: "Sumimao" },
    { value: "Tacunan", name: "Tacunan" },
    { value: "Tagakpan", name: "Tagakpan" },
    { value: "Tagluno", name: "Tagluno" },
    { value: "Tagurano", name: "Tagurano" },
    { value: "Talandang", name: "Talandang" },
    { value: "Talomo", name: "Talomo" },
    { value: "Talomo River", name: "Talomo River" },
    { value: "Tamayong", name: "Tamayong" },
    { value: "Tambobong", name: "Tambobong" },
    { value: "Tamugan", name: "Tamugan" },
    { value: "Tapak", name: "Tapak" },
    { value: "Tawan-tawan", name: "Tawan-tawan" },
    { value: "Tibuloy", name: "Tibuloy" },
    { value: "Tibungco", name: "Tibungco" },
    { value: "Tigatto", name: "Tigatto" },
    { value: "Toril", name: "Toril" },
    { value: "Tugbok", name: "Tugbok" },
    { value: "Tungakalan", name: "Tungakalan" },
    { value: "Ubalde", name: "Ubalde" },
    { value: "Ula", name: "Ula" },
    { value: "Vicente Hizon Sr.", name: "Vicente Hizon Sr." },
    { value: "Waan", name: "Waan" },
    { value: "Wangan", name: "Wangan" },
    { value: "Wilfredo Aquino", name: "Wilfredo Aquino" },
    { value: "Wines", name: "Wines" },
  ];

  const panaboBrgy = [
    { value: "A. O. Floirendo", name: "A. O. Floirendo" },
    { value: "Datu Abdul Dadia", name: "Datu Abdul Dadia" },
    { value: "Buenavista", name: "Buenavista" },
    { value: "Cacao", name: "Cacao" },
    { value: "Cagagohan", name: "Cagagohan" },
    { value: "Consolacion", name: "Consolacion" },
    { value: "Dapco", name: "Dapco" },
    { value: "Gredu (Poblacion)", name: "Gredu (Poblacion)" },
    { value: "J.P. Laurel", name: "J.P. Laurel" },
    { value: "Kasilak", name: "Kasilak" },
    { value: "Katipunan", name: "Katipunan" },
    { value: "Katualan", name: "Katualan" },
    { value: "Kauswagan", name: "Kauswagan" },
    { value: "Kiotoy", name: "Kiotoy" },
    { value: "Little Panay", name: "Little Panay" },
    { value: "Lower Panaga (Roxas)", name: "Lower Panaga (Roxas)" },
    { value: "Mabunao", name: "Mabunao" },
    { value: "Maduao", name: "Maduao" },
    { value: "Malativas", name: "Malativas" },
    { value: "Manay", name: "Manay" },
    { value: "Nanyo", name: "Nanyo" },
    { value: "New Malaga (Dalisay)", name: "New Malaga (Dalisay)" },
    { value: "New Malitbog", name: "New Malitbog" },
    { value: "New Pandan (Poblacion)", name: "New Pandan (Poblacion)" },
    { value: "New Visayas", name: "New Visayas" },
    { value: "Quezon", name: "Quezon" },
    { value: "Salvacion", name: "Salvacion" },
    { value: "San Francisco (Poblacion)", name: "San Francisco (Poblacion)" },
    { value: "San Nicolas", name: "San Nicolas" },
    { value: "San Pedro", name: "San Pedro" },
    { value: "San Roque", name: "San Roque" },
    { value: "San Vicente", name: "San Vicente" },
    { value: "Santa Cruz", name: "Santa Cruz" },
    { value: "Santo Niño (Poblacion)", name: "Santo Niño (Poblacion)" },
    { value: "Sindaton", name: "Sindaton" },
    { value: "Southern Davao", name: "Southern Davao" },
    { value: "Tagpore", name: "Tagpore" },
    { value: "Tibungol", name: "Tibungol" },
    { value: "Upper Licanan", name: "Upper Licanan" },
    { value: "Waterfall", name: "Waterfall" },
  ];

  const tagumBrgy = [
    { value: "Apokon", name: "Apokon" },
    { value: "Bincungan", name: "Bincungan" },
    { value: "Busaon", name: "Busaon" },
    { value: "Canocotan", name: "Canocotan" },
    { value: "Cuambogan", name: "Cuambogan" },
    { value: "La Filipina", name: "La Filipina" },
    { value: "Liboganon", name: "Liboganon" },
    { value: "Madaum", name: "Madaum" },
    { value: "Magdum", name: "Magdum" },
    { value: "Magugpo Poblacion", name: "Magugpo Poblacion" },
    { value: "Magugpo East", name: "Magugpo East" },
    { value: "Magugpo North", name: "Magugpo North" },
    { value: "Magugpo South", name: "Magugpo South" },
    { value: "Magugpo West", name: "Magugpo West" },
    { value: "Mankilam", name: "Mankilam" },
    { value: "New Balamban", name: "New Balamban" },
    { value: "Nueva Fuerza", name: "Nueva Fuerza" },
    { value: "Pagsabangan", name: "Pagsabangan" },
    { value: "Pandapan", name: "Pandapan" },
    { value: "San Agustin", name: "San Agustin" },
    { value: "San Isidro", name: "San Isidro" },
    { value: "San Miguel (Camp 4)", name: "San Miguel (Camp 4)" },
    { value: "Visayan Village", name: "Visayan Village" },
  ];

  const butuanBrgy = [
    { value: "Agao Poblacion", name: "Agao Poblacion" },
    { value: "Agusan Pequeño", name: "Agusan Pequeño" },
    { value: "Ambago", name: "Ambago" },
    { value: "Amparo", name: "Amparo" },
    { value: "Ampayon", name: "Ampayon" },
    { value: "Anticala", name: "Anticala" },
    { value: "Antongalon", name: "Antongalon" },
    { value: "Aupagan", name: "Aupagan" },
    { value: "Baan KM 3", name: "Baan KM 3" },
    { value: "Baan Riverside Poblacion", name: "Baan Riverside Poblacion" },
    { value: "Babag", name: "Babag" },
    { value: "Bading Poblacion", name: "Bading Poblacion" },
    { value: "Bancasi", name: "Bancasi" },
    { value: "Banza", name: "Banza" },
    { value: "Baobaoan", name: "Baobaoan" },
    { value: "Basag", name: "Basag" },
    { value: "Bayanihan Poblacion", name: "Bayanihan Poblacion" },
    { value: "Bilay", name: "Bilay" },
    { value: "Bitan-agan", name: "Bitan-agan" },
    { value: "Bit-os", name: "Bit-os" },
    { value: "Bobon", name: "Bobon" },
    { value: "Bonbon", name: "Bonbon" },
    { value: "Bugabus", name: "Bugabus" },
    { value: "Bugsukan", name: "Bugsukan" },
    { value: "Buhangin Poblacion", name: "Buhangin Poblacion" },
    { value: "Cabcabon", name: "Cabcabon" },
    { value: "Camayahan", name: "Camayahan" },
    { value: "Dagohoy Poblacion", name: "Dagohoy Poblacion" },
    { value: "Dankias", name: "Dankias" },
    { value: "De Oro", name: "De Oro" },
    { value: "Diego Silang Poblacion", name: "Diego Silang Poblacion" },
    { value: "Don Francisco", name: "Don Francisco" },
    { value: "Doongan", name: "Doongan" },
    { value: "Dulag", name: "Dulag" },
    { value: "Dumalagan", name: "Dumalagan" },
    { value: "Florida", name: "Florida" },
    { value: "Golden Ribbon Poblacion", name: "Golden Ribbon Poblacion" },
    { value: "Holy Redeemer Poblacion", name: "Holy Redeemer Poblacion" },
    { value: "Humabon Poblacion", name: "Humabon Poblacion" },
    { value: "Imadejas Poblacion", name: "Imadejas Poblacion" },
    { value: "Jose Rizal Poblacion", name: "Jose Rizal Poblacion" },
    { value: "Kinamlutan", name: "Kinamlutan" },
    { value: "Lapu-lapu Poblacion", name: "Lapu-lapu Poblacion" },
    { value: "Lemon", name: "Lemon" },
    { value: "Leon Kilat Poblacion", name: "Leon Kilat Poblacion" },
    { value: "Libertad", name: "Libertad" },
    { value: "Limaha Poblacion", name: "Limaha Poblacion" },
    { value: "Los Angeles", name: "Los Angeles" },
    { value: "Lumbocan", name: "Lumbocan" },
    { value: "Maguinda", name: "Maguinda" },
    { value: "Mahay", name: "Mahay" },
    { value: "Mahogany Poblacion", name: "Mahogany Poblacion" },
    { value: "Maibu", name: "Maibu" },
    { value: "Mandamo", name: "Mandamo" },
    { value: "Manila de Bugabus", name: "Manila de Bugabus" },
    { value: "Maon Poblacion", name: "Maon Poblacion" },
    { value: "Masao", name: "Masao" },
    { value: "Maug", name: "Maug" },
    {
      value: "New Society Village Poblacion",
      name: "New Society Village Poblacion",
    },
    { value: "Nong-Nong", name: "Nong-Nong" },
    { value: "Obrero Poblacion", name: "Obrero Poblacion" },
    { value: "Ong Yiu Poblacion", name: "Ong Yiu Poblacion" },
    { value: "Pagatpatan", name: "Pagatpatan" },
    { value: "Pangabugan", name: "Pangabugan" },
    { value: "Pianing", name: "Pianing" },
    { value: "Pigdaulan", name: "Pigdaulan" },
    { value: "Pinamanculan", name: "Pinamanculan" },
    { value: "Port Poyohon Poblacion", name: "Port Poyohon Poblacion" },
    { value: "Rajah Soliman Poblacion", name: "Rajah Soliman Poblacion" },
    { value: "Salvacion", name: "Salvacion" },
    { value: "San Ignacio Poblacion", name: "San Ignacio Poblacion" },
    { value: "San Mateo", name: "San Mateo" },
    { value: "Santo Niño", name: "Santo Niño" },
    { value: "San Vicente", name: "San Vicente" },
    { value: "Sikatuna Poblacion", name: "Sikatuna Poblacion" },
    { value: "Silongan Poblacion", name: "Silongan Poblacion" },
    { value: "Sumile", name: "Sumile" },
    { value: "Sumilihon", name: "Sumilihon" },
    { value: "Tagabaca", name: "Tagabaca" },
    { value: "Taguibo", name: "Taguibo" },
    { value: "Taligaman", name: "Taligaman" },
    { value: "Tandang Sora Poblacion", name: "Tandang Sora Poblacion" },
    { value: "Tiniwisan", name: "Tiniwisan" },
    { value: "Tungao", name: "Tungao" },
    { value: "Urduja Poblacion", name: "Urduja Poblacion" },
    { value: "Villa Kananga", name: "Villa Kananga" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getBranchList = () => {
    var params = { proprietor_id: userData.data.id };
    axios
      .get("proprietor/get/branch-list", { params })
      .then((response) => {
        const data = response.data;
        setBranchList({ data, ready: true });
        // setProductsCount(data.length);
      })
      .catch((err) => console.log("error", err));
  };

  const handleCreateNewMerchant = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("proprietor_id", userData.data.id);
    setIsProcess(true);
    setAddBranchDiag(false);
    axios
      .post("proprietor/create/merchant", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully added new branch");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getBranchList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    getBranchList();
    // eslint-disable-next-line
  }, []);

  const searchable = branchList.data.filter((data) => {
    return (
      data.proprietorbranchesmerchantinfo.business_name
        .toLowerCase()
        .indexOf(search.trim()) !== -1
    );
  });

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: "100vw",
          maxWidth: "1200px",
          height: "calc(100vh - 64px)",
          paddingX: "8px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box
              component={Paper}
              elevation={1}
              sx={{
                padding: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box flexGrow={1}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Branch List
                  </Typography>
                </Box>
                <Box>
                  {isMobile ? (
                    <Tooltip title="Add Branch">
                      <IconButton
                        onClick={() => {
                          setAddBranchDiag(true);
                          // if (
                          //     merchantLocation.data.latitude === undefined &&
                          //     merchantLocation.data.longitude === undefined
                          // ) {
                          //     setLocationDialog(true);
                          // } else {
                          //     setAddProductDiag(true);
                          // }
                        }}
                        disabled={
                          branchList.data.length >
                          (branchList.data.length > 0 &&
                            branchList.data[0].proprietorbranchesproprietorinfo
                              .branch_limit)
                        }
                      >
                        <AddBusinessIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add Branch">
                      <Button
                        startIcon={<AddBusinessIcon />}
                        variant="text"
                        onClick={() => {
                          setAddBranchDiag(true);
                          // if (
                          //   merchantLocation.data.latitude === undefined &&
                          //   merchantLocation.data.longitude === undefined
                          // ) {
                          //   setLocationDialog(true);
                          // } else {
                          //   setAddProductDiag(true);
                          // }
                        }}
                        sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                        disabled={
                          branchList.data.length >
                          (branchList.data.length > 0 &&
                            branchList.data[0].proprietorbranchesproprietorinfo
                              .branch_limit)
                        }
                      >
                        Add
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <TextField
                    label="Search branch"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    autoComplete="off"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {branchList.ready ? (
                  searchable.length > 0 ? (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      {searchable.map((data, index) => (
                        <Fragment key={index}>
                          <ListItemButton
                            selected={
                              selectedData !== null &&
                              selectedData.id === data.id
                            }
                            onClick={() => setSelectedData(data)}
                          >
                            <ListItemText
                              primary={
                                data.proprietorbranchesmerchantinfo
                                  .business_name
                              }
                              secondary={
                                <React.Fragment>
                                  {data.proprietorbranchesmerchantinfo.street},{" "}
                                  {data.proprietorbranchesmerchantinfo.barangay}
                                  , {data.proprietorbranchesmerchantinfo.city}
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                          {searchable.length !== index + 1 && (
                            <Divider component="li" />
                          )}
                        </Fragment>
                      ))}
                    </List>
                  ) : (
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <Typography
                        align="center"
                        sx={{
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        No branches added
                      </Typography>
                    </Box>
                  )
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box flexGrow={1} />
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 25]}
                    component="div"
                    count={searchable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <BranchDetails
              selectedData={selectedData}
              close={() => setSelectedData(null)}
              getBranchList={() => getBranchList()}
            />
          </Grid>
        </Grid>
      </Box>

      <Dialog open={addBranchDiag} onClose={() => setAddBranchDiag(false)}>
        <Box sx={{ padding: 2 }}>
          <Box sx={{ display: "flex" }}>
            <Box flexGrow={1} />
            <IconButton onClick={() => setAddBranchDiag(false)}>
              <Close />
            </IconButton>
          </Box>
          <Box>
            <form onSubmit={handleCreateNewMerchant}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& .MuiInput-input": {
                            borderRadius: "8px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#000",
                              backgroundColor: "#f4f5f7",
                              fontWeight: 700,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business Name"
                        variant="standard"
                        fullWidth
                        placeholder="Business Name Here"
                        size="small"
                        name="business_name"
                        required
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& .MuiInput-input": {
                            borderRadius: "8px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#000",
                              backgroundColor: "#f4f5f7",
                              fontWeight: 700,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business Street, House #, Bldg."
                        variant="standard"
                        fullWidth
                        placeholder="Street, House #, Bldg. Here"
                        size="small"
                        name="street"
                        required
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#AEAEAE",
                              backgroundColor: "#f4f5f7",
                              fontFamily: "Futura Md Bt",
                              borderRadius: "8px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              fontSize: 14,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business City/Municipality"
                        name="city"
                        required
                        value={inputedCity}
                        onChange={(e) => setInputedCity(e.target.value)}
                      >
                        <option value=""> - Select city - </option>
                        {cityList.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.name}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#AEAEAE",
                              backgroundColor: "#f4f5f7",
                              fontFamily: "Futura Md Bt",
                              borderRadius: "8px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              fontSize: 14,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business Barangay"
                        name="barangay"
                        required
                      >
                        <option value=""> - Select category - </option>
                        {inputedCity === "Davao City"
                          ? davaoBrgy.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.name}
                              </option>
                            ))
                          : inputedCity === "Tagum City"
                          ? tagumBrgy.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.name}
                              </option>
                            ))
                          : inputedCity === "Panabo City"
                          ? panaboBrgy.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.name}
                              </option>
                            ))
                          : inputedCity === "Butuan City"
                          ? butuanBrgy.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.name}
                              </option>
                            ))
                          : null}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#AEAEAE",
                              backgroundColor: "#f4f5f7",
                              fontFamily: "Futura Md Bt",
                              borderRadius: "8px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              fontSize: 14,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business Type"
                        name="business_type"
                        required
                        value={inputedType}
                        onChange={(e) => setInputedType(e.target.value)}
                      >
                        <option value=""> - Select type - </option>
                        <option value="food">Food</option>
                        <option value="nonfood">NonFood</option>
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#AEAEAE",
                              backgroundColor: "#f4f5f7",
                              fontFamily: "Futura Md Bt",
                              borderRadius: "8px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              fontSize: 14,
                            },
                          },
                        }}
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        fullWidth
                        variant="standard"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business Category"
                        name="business_category"
                        required
                      >
                        <option value=""> - Select category - </option>
                        {inputedType === "food"
                          ? foodCat.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.name}
                              </option>
                            ))
                          : inputedType === "nonfood"
                          ? nonfoodCat.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.name}
                              </option>
                            ))
                          : null}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& .MuiInput-input": {
                            borderRadius: "8px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#000",
                              backgroundColor: "#f4f5f7",
                              fontWeight: 700,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="First Name"
                        variant="standard"
                        fullWidth
                        placeholder="First Name Here"
                        size="small"
                        name="firstname"
                        required
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& .MuiInput-input": {
                            borderRadius: "8px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#000",
                              backgroundColor: "#f4f5f7",
                              fontWeight: 700,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        placeholder="Last Name Here"
                        size="small"
                        name="lastname"
                        required
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& .MuiInput-input": {
                            borderRadius: "8px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#000",
                              backgroundColor: "#f4f5f7",
                              fontWeight: 700,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Contact Number"
                        variant="standard"
                        fullWidth
                        placeholder="Contact Number Here"
                        size="small"
                        name="contact_no"
                        required
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#000",
                            fontWeight: 700,
                          },
                          "& .MuiInput-input": {
                            borderRadius: "8px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                          "& label + .MuiInput-root-emKjRF": {
                            marginTop: "22px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            style: {
                              color: "#000",
                              backgroundColor: "#f4f5f7",
                              fontWeight: 700,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Email Address"
                        variant="standard"
                        fullWidth
                        placeholder="Email Address Here"
                        size="small"
                        name="email"
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* here */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 2,
                }}
              >
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                      fontWeight: 700,
                    },
                    "& .MuiInput-input": {
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      fontSize: 14,
                    },
                    "& label + .MuiInput-root-emKjRF": {
                      marginTop: "22px",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      style: {
                        color: "#000",
                        backgroundColor: "#f4f5f7",
                        fontWeight: 700,
                      },
                    },
                    endAdornment: (
                      <InputAdornment
                        sx={{
                          minHeight: "44px",
                          marginLeft: "0px",
                          backgroundColor: "rgb(244, 245, 247)",
                          borderTopRightRadius: "8px",
                          borderBottomRightRadius: "8px",
                        }}
                        variant="filled"
                        position="end"
                      >
                        {passwordShow ? (
                          <VisibilityOff
                            fontSize="small"
                            sx={{
                              color: "#AEAEAE",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={() => setPasswordShow(false)}
                          />
                        ) : (
                          <Visibility
                            fontSize="small"
                            sx={{
                              color: "#AEAEAE",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={() => setPasswordShow(true)}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Set Password"
                  variant="standard"
                  fullWidth
                  size="small"
                  name="new_password"
                  type={passwordShow ? "text" : "password"}
                  placeholder="•••••••"
                  required
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  mb: 1,
                }}
              >
                <Button
                  sx={{
                    color: "#fff",
                    textTransform: "none",
                    height: 40,
                    backgroundColor:
                      process.env.REACT_APP_APP_NAME === "estherph"
                        ? "#261138"
                        : "#ff9f1d",
                  }}
                  fullWidth
                  size="small"
                  type="submit"
                  disabled={isProcess}
                >
                  Create manager account
                </Button>
              </Box>

              {/* <Box
                                sx={{
                                    display: "flex"
                                }}
                            >
                                <Button
                                    sx={{
                                        color: "#000",
                                        textTransform: "none",
                                        fontWeight: 700,
                                        height: 40,
                                        borderRadius: 2,
                                        marginBottom: 2,
                                        backgroundColor: "#e5e5e5",
                                    }}
                                    fullWidth
                                    size="small"
                                    onClick={() => setAddBranchDiag(false)}
                                    startIcon={
                                        <ArrowBackIos
                                            sx={{ fontSize: "12px ", color: "#AEAEAE" }}
                                        />
                                    }
                                >
                                    Back
                                </Button>
                            </Box> */}
            </form>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ProprietorPage;
