import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import GroupIcon from "@mui/icons-material/Group";
import MopedIcon from "@mui/icons-material/Moped";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, CardMedia, CssBaseline, Dialog } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import CustomersAbout from "./customers";
import MerchantsAbout from "./merchants";
import RidersAbout from "./riders";
import InfoIcon from "@mui/icons-material/Info";
import { UserInfo } from "../context";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AdminsAbout from "./admin";
import axios from "axios";
import OthersAbout from "./others";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminProfile from "./profile";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ProprietorsAbout from "./proprietors";

const img1 =
  process.env.REACT_APP_APP_NAME === "estherph"
    ? require("./../../assets/socialmediaicon/esther.png")
    : require("./../../assets/socialmediaicon/hatood.png");

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  backgroundColor:
    process.env.REACT_APP_APP_NAME === "estherph" ? "#261138" : "#ff9f1d",
  // border: "1px solid red",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor:
    process.env.REACT_APP_APP_NAME === "estherph" ? "#261138" : "#ff9f1d",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminPage = () => {
  const navigate = useNavigate();
  const { userData, handleLogoutUser } = React.useContext(UserInfo);
  const module = userData.data.module;
  const [currentTab, setCurrentTab] = React.useState(
    module === "admin" ? "customers" : "admins"
  );
  const [userRestriction, setUserRestriction] = React.useState([]);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  // const [isProcess, setIsProcess] = React.useState(false);

  const getAllRestriction = React.useCallback(async () => {
    var params = { user_id: userData.data.id };
    await axios
      .get("user/admin/get/user-restriction", {
        params,
      })
      .then((response) => {
        const data = response.data;
        setUserRestriction(data);
      })
      .catch((error) => console.log("Error: ", error));
  }, [userData.data.id]);

  // const handleLogoutOther = () => {
  //   var formdata = new FormData();
  //   setIsProcess(true);
  //   setLogoutDialog(false);
  //   axios
  //     .post("other/logout", formdata, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("estherToken")}`,
  //         Accept: "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.message === "success") {
  //         setIsProcess(false);
  //         localStorage.removeItem("estherToken");
  //         setTimeout(() => {
  //           navigate("/");
  //           navigate(0);
  //         }, 1000);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsProcess(false);
  //     });
  // };

  React.useEffect(() => {
    if (module === "admin") {
      getAllRestriction();
    }
  }, [getAllRestriction, module]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={false}>
        <DrawerHeader>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CardMedia
              component="img"
              src={`${img1}`}
              alt=""
              sx={{ cursor: "pointer", width: 40 }}
              onClick={() => {
                // window.location.href = "/";
                navigate("/");
              }}
            />
          </Box>
        </DrawerHeader>
        <Divider />

        <List
          style={{
            backgroundColor:
              process.env.REACT_APP_APP_NAME === "estherph"
                ? "#261138"
                : "#ff9f1d",
          }}
        >
          <ListItem
            selected={currentTab === "admins"}
            disablePadding
            sx={{ display: module === "superadmin" ? "block" : "none" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("admins")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <AdminPanelSettingsIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Admins"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            selected={currentTab === "customers"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("customers")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <GroupIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Customers"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            selected={currentTab === "merchants"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("merchants")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <StoreMallDirectoryIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Merchants"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            selected={currentTab === "proprietor"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("proprietor")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <ManageAccountsIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Proprietors"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            selected={currentTab === "riders"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("riders")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <MopedIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Riders"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            selected={currentTab === "others"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("others")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <EngineeringIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Others"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>
        </List>

        <Divider />

        <List
          style={{
            backgroundColor:
              process.env.REACT_APP_APP_NAME === "estherph"
                ? "#261138"
                : "#ff9f1d",
          }}
        >
          <ListItem
            selected={currentTab === "my-profile"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => setCurrentTab("my-profile")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <AccountCircleIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Profile"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
              onClick={() => {
                setLogoutDialog(true);
                // localStorage.removeItem("estherToken");
                // navigate("/");
                // navigate(0);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Box
        component="main"
        style={{ display: "flex", flexDirection: "column", width: "100vw" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "64px",
            paddingX: 1,
          }}
        >
          {currentTab === "admins" && (
            <Typography>
              {" "}
              <InfoIcon sx={{ fontSize: "15px", color: "blue" }} /> All About
              Admins
            </Typography>
          )}
          {currentTab === "customers" && (
            <Typography>
              {" "}
              <InfoIcon sx={{ fontSize: "15px", color: "blue" }} /> All About
              Customers
            </Typography>
          )}
          {currentTab === "merchants" && (
            <Typography>
              {" "}
              <InfoIcon sx={{ fontSize: "15px", color: "blue" }} /> All About
              Merchants
            </Typography>
          )}
          {currentTab === "proprietor" && (
            <Typography>
              {" "}
              <InfoIcon sx={{ fontSize: "15px", color: "blue" }} /> All About
              Proprietors
            </Typography>
          )}
          {currentTab === "riders" && (
            <Typography>
              {" "}
              <InfoIcon sx={{ fontSize: "15px", color: "blue" }} /> All About
              Riders
            </Typography>
          )}
          {currentTab === "others" && (
            <Typography>
              {" "}
              <InfoIcon sx={{ fontSize: "15px", color: "blue" }} />
              Other functions
            </Typography>
          )}
          {/* {currentTab === 'my-profile' && (
            <Typography> <InfoIcon sx={{ fontSize: "15px", color: "blue" }} />My Profile</Typography>
          )} */}
        </Box>
        <Box style={{ display: "flex", width: "100%", padding: 3 }}>
          {currentTab === "admins" && <AdminsAbout />}
          {currentTab === "customers" && (
            <CustomersAbout userRestriction={userRestriction} module={module} />
          )}
          {currentTab === "merchants" && (
            <MerchantsAbout userRestriction={userRestriction} module={module} />
          )}
          {currentTab === "proprietor" && (
            <ProprietorsAbout
              userRestriction={userRestriction}
              module={module}
            />
          )}
          {currentTab === "riders" && (
            <RidersAbout userRestriction={userRestriction} module={module} />
          )}
          {currentTab === "others" && (
            <OthersAbout userRestriction={userRestriction} module={module} />
          )}
          {currentTab === "my-profile" && <AdminProfile />}
        </Box>
      </Box>

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6">
            <b>Logging out your account...</b>
          </Typography>
          <Typography variant="caption">
            <i>Do you really want to logout your account?</i>
          </Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              onClick={() => {
                setLogoutDialog(false);
              }}
              sx={{ mr: 1, backgroundColor: "#AEAEAE" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // onClick={() => {
              //   localStorage.removeItem("estherToken");
              //   setLogoutDialog(false)
              //   setTimeout(() => {
              //     navigate("/");
              //     navigate(0);
              //   }, 1000);
              // }}
              onClick={() => {
                handleLogoutUser();
                setTimeout(() => {
                  navigate("/");
                  navigate(0);
                }, 1000);
              }}
              // disabled={isProcess}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdminPage;
