import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useRef } from "react";
import { UserInfo } from "../../context";
import { useEffect } from "react";
import { useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import moment from "moment";
import cities from "../../../assets/city.json";
import provinces from "../../../assets/province.json";

const MerchantBanner = () => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    React.useContext(UserInfo);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [merchantBanner, setMerchantBanner] = useState({
    data: [],
    ready: false,
  });
  const [addMerchantBannerDialog, setAddMerchantBannerDialog] =
    React.useState(false);
  const [image, setImage] = useState([]);
  const [merchantList, setMerchantList] = useState({ data: [], ready: false });
  const [merchantName, setMerchantName] = useState(null);
  const [merchantID, setMerchantID] = useState(null);

  const [selectedProvince, setSelectedProvince] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const selectRef = useRef(null);

  const getMerchantBanner = () => {
    var params = { category: "merchant", device: "mobile" };
    axios
      .get("user/admin/get/banner", { params })
      .then((response) => {
        const data = response.data;
        setMerchantBanner({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageChangeNew = (files) => {
    setImage(files);
  };

  const handleNewMerchantBanner = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("image", image[0]);
    formdata.append("category", "merchant");
    formdata.append("device", "mobile");
    formdata.append("merchant_id", merchantID === null ? "" : merchantID);
    formdata.append("merchant_name", merchantName === null ? "" : merchantName);

    var err = [];
    if (image.length < 1) {
      err = "error";
      setMessage("Image required");
      setSeverity("error");
      setOpenSnackbar(true);
    }
    if (err.length > 0) {
      console.log("form has a problem!");
    } else {
      setIsProcess(true);
      setAddMerchantBannerDialog(false);
      axios
        .post("user/admin/create/new-merchant-banner", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setMessage("Successfully added merchant banner");
            setSeverity("success");
            setTimeout(() => {
              setIsProcess(false);
              setOpenSnackbar(true);
              setTimeout(() => {
                getMerchantBanner();
              }, 500);
            }, 1000);
          }
        })
        .catch((error) => {
          setIsProcess(false);
          // console.log("error", error);
        });
    }
  };

  const getMerchantList = (city) => {
    var params = { province: selectedProvince, city };
    axios
      .get("user/admin/get/merchant-by-city", { params })
      .then((response) => {
        const data = response.data;
        setMerchantList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    getMerchantBanner();
    // getMerchantList();
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const filtered = cities.filter(
        (city) => city.province_code === selectedProvince
      );
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]);
    }
    const inputElement = selectRef.current?.input?.input;
    if (inputElement) {
      inputElement.setAttribute("autocomplete", "off");
    }
  }, [selectedProvince]);

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Add Merchant Banner">
            <IconButton
              onClick={() => {
                setAddMerchantBannerDialog(true);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Date</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
              {/* <TableCell align='center'><b>Action</b></TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {merchantBanner.ready ? (
              merchantBanner.data.length > 0 ? (
                merchantBanner.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {moment(data.created_at).format("MMMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      {parseFloat(data.status) === 1 ? "Active" : "Deactive"}
                    </TableCell>
                    {/* <TableCell align='center'>
                                            <Tooltip title="Complete">
                                                <IconButton
                                                // onClick={() => handleUpdateConfirm(data.code, 'complete')}
                                                >
                                                    <DoneAllIcon sx={{ color: '#e5e5e5' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    style={{ color: "red" }}
                  >
                    No merchant banner found!
                  </TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={merchantBanner.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={addMerchantBannerDialog}
        onClose={() => setAddMerchantBannerDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>
                Add Merchant Banner
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddMerchantBannerDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <form onSubmit={handleNewMerchantBanner}>
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              >
                <DropzoneArea
                  onChange={handleImageChangeNew}
                  acceptedFiles={["image/jpeg", "image/png"]}
                  dropzoneText={"Upload banner"}
                  maxFileSize={2000000}
                  filesLimit={1}
                  dropzoneClass={"dropZoneCustom"}
                  dropzoneParagraphClass={"dropZoneCustomText"}
                />
              </Box>
              {/* NEW  */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Province Name"
                  name="province"
                  required
                  // value={inputedCity}
                  onChange={(e) => setSelectedProvince(e.target.value)}
                  ref={selectRef}
                >
                  <option value=""> - Select province - </option>
                  {provinces.map((province) => (
                    <option
                      key={province.province_code}
                      value={province.province_code}
                    >
                      {province.province_name}
                    </option>
                  ))}
                </TextField>
              </Box>

              {selectedProvince !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="City/Municipality Name"
                    name="city"
                    required
                    ref={selectRef}
                    onChange={(e) => getMerchantList(e.target.value)}
                  >
                    <option value=""> - Select city - </option>
                    {filteredCities.map((city) => (
                      <option key={city.city_code} value={city.city_code}>
                        {city.city_name}
                      </option>
                    ))}
                  </TextField>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Merchant"
                  // required
                  onChange={(e) => {
                    setMerchantName(e.target.selectedOptions[0].label);
                    setMerchantID(e.target.value);
                  }}
                  value={merchantID !== null ? merchantID : ""}
                >
                  <option value="">-</option>
                  {merchantList.ready &&
                    merchantList.data.length > 0 &&
                    merchantList.data.map((data, index) => (
                      <option key={index} value={data.id}>
                        {/* {console.log("DATA", data)} */}
                        {data.business_name}
                      </option>
                    ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      paddingX: 2,
                      backgroundColor: "#d65d46",
                    }}
                    type="submit"
                    disabled={isProcess}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MerchantBanner;
