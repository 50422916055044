import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Badge, Button, CssBaseline, Dialog } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { UserInfo } from "../components/context";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/material/styles";
import axios from "axios";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const CustomerAppbar = ({ module, route, cartOrder, checkoutOrder }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { userData } = React.useContext(UserInfo);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutOther = () => {
    var formdata = new FormData();
    setIsProcess(true);
    setLogoutDialog(false);
    axios
      .post("other/logout", formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("estherToken")}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          setIsProcess(false);
          localStorage.removeItem("estherToken");
          setTimeout(() => {
            navigate("/");
            navigate(0);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  React.useEffect(() => {}, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        // elevation={1}
        elevation={0}
        position="static"
        sx={{ backgroundColor: "#fff", color: "#000", minHeight: "64px" }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: "flex",
                fontFamily: "Futura Md Bt",
                fontSize: 28,
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {process.env.REACT_APP_APP_NAME === "estherph"
                ? "Esther PH"
                : "Hatood"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <IconButton sx={{ p: 0, mr: 1 }}>
                <Avatar
                  alt="User"
                  src={userData.data.avatar}
                  sx={{ backgroundColor: "#2264f6", width: 20, height: 20 }}
                />
              </IconButton> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="a"
                sx={{
                  // mr: 0.5,
                  display: "flex",
                  fontFamily: "Futura Md Bt",
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleOpenUserMenu}
              >
                {/* Welcome  */}
                {userData.data.name}
              </Typography>

              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {anchorElUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>

              <IconButton
                sx={{ display: module === "customer" ? "flex" : "none" }}
                onClick={() => navigate("/customer/cart")}
              >
                <StyledBadge
                  badgeContent={
                    cartOrder !== undefined
                      ? cartOrder.data.length > 0
                        ? cartOrder.data.length
                        : 0 + checkoutOrder.data.length > 0
                        ? checkoutOrder.data.length
                        : 0
                      : 0
                  }
                  color="secondary"
                >
                  <ShoppingCartIcon sx={{ fontSize: 20 }} />
                </StyledBadge>
              </IconButton>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {route.map((data, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      navigate(data.path);
                      handleCloseUserMenu();
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ mr: 1 }}>{data.icon}</Box>
                      <Typography
                        textAlign="left"
                        variant="subtitle2"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}

                <MenuItem
                  onClick={() => {
                    setLogoutDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ mr: 1 }}>
                      <ExitToApp fontSize="small" />
                    </Box>
                    <Typography
                      textAlign="right"
                      variant="subtitle2"
                      sx={{ fontWeight: 700 }}
                    >
                      Log Out
                    </Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6">
            <b>Logging out your account...</b>
          </Typography>
          <Typography variant="caption">
            <i>Do you really want to logout your account?</i>
          </Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              onClick={() => {
                setLogoutDialog(false);
              }}
              sx={{ mr: 1, backgroundColor: "#AEAEAE" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // onClick={() => {
              //   localStorage.removeItem("estherToken");
              //   setLogoutDialog(false)
              //   setTimeout(() => {
              //     navigate("/");
              //     navigate(0);
              //   }, 1000);
              // }}
              onClick={() => {
                handleLogoutOther();
              }}
              disabled={isProcess}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
export default CustomerAppbar;
