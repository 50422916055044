import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Badge,
  Button,
  CardMedia,
  CssBaseline,
  Dialog,
  Tooltip,
} from "@mui/material";
import {
  ExitToApp,
  Home,
  Inventory2,
  KeyboardArrowDown,
  Logout,
  ManageAccounts,
} from "@mui/icons-material";
// import { UserInfo } from "../components/context";
import { useNavigate } from "react-router-dom";
// import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, alpha } from "@mui/material/styles";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import moment from "moment";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import OrderDetails from "../components/merchant/order-details";
import { isMobile } from "react-device-detect";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
// import axios from "axios";
import { UserInfo } from "../components/context";

const img1 =
  process.env.REACT_APP_APP_NAME === "estherph"
    ? require("./../assets/socialmediaicon/esther.png")
    : require("./../assets/socialmediaicon/hatood.png");

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MerchantAppbar = ({
  module,
  route,
  merchantProducts,
  productNotView,
}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElBell, setAnchorElBell] = React.useState(null);
  const navigate = useNavigate();
  const {
    // userData,
    handleLogoutUser,
  } = React.useContext(UserInfo);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [viewOrder, setViewOrder] = React.useState(false);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [isProcess, setIsProcess] = React.useState(false);

  const open = Boolean(anchorEl);
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleOpenBellMenu = (event) => {
    setAnchorElBell(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseBellMenu = () => {
    setAnchorElBell(null);
  };

  // const handleLogoutOther = () => {
  //   var formdata = new FormData();
  //   setIsProcess(true);
  //   setLogoutDialog(false);
  //   axios
  //     .post("other/logout", formdata, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("estherToken")}`,
  //         Accept: "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.message === "success") {
  //         setIsProcess(false);
  //         localStorage.removeItem("estherToken");
  //         setTimeout(() => {
  //           navigate("/");
  //           navigate(0);
  //         }, 1000);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsProcess(false);
  //     });
  // };

  React.useEffect(() => {}, []);

  return (
    <Box sx={{ display: "flex" }}>
      {/* console.log(moment([2017, 01, 05]).fromNow()); */}

      <CssBaseline />
      <AppBar
        elevation={0}
        // elevation={1}
        position="static"
        sx={{
          backgroundColor:
            process.env.REACT_APP_APP_NAME === "estherph"
              ? "#261138"
              : "#ff9f1d",
          color: "#000",
          minHeight: "64px",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <CardMedia
              component="img"
              src={`${img1}`}
              alt=""
              sx={{ cursor: "pointer", width: 50 }}
              onClick={() => navigate("/")}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <IconButton sx={{ p: 0, mr: 1 }}>
                <Avatar
                  alt="User"
                  src={userData.data.avatar}
                  sx={{ backgroundColor: "#2264f6", width: 20, height: 20 }}
                />
              </IconButton> */}
              {/* <Typography
                variant="subtitle1"
                noWrap
                component="a"
                sx={{
                  display: "flex",
                  color: "#fff",
                  cursor: "pointer"
                }}
                onClick={handleOpenUserMenu}
              >
                {userData.data.name}
              </Typography> */}

              {isMobile ? (
                <>
                  <Tooltip title="Dashboard">
                    <IconButton
                      sx={{
                        display: "flex",
                      }}
                      onClick={() => navigate("/")}
                    >
                      <Home sx={{ fontSize: 20, color: "#fff" }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Profile">
                    <IconButton
                      sx={{
                        display: "flex",
                      }}
                      onClick={() => navigate("/profile")}
                    >
                      <ManageAccounts sx={{ fontSize: 20, color: "#fff" }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Product">
                    <IconButton
                      sx={{
                        display: "flex",
                      }}
                      onClick={() => navigate("/product")}
                    >
                      <Inventory2 sx={{ fontSize: 20, color: "#fff" }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delivery">
                    <IconButton
                      // sx={{
                      //   display: "flex",
                      //   mr: 2,
                      // }}
                      // onClick={() => navigate("/delivery")}

                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                      <LocalShippingIcon sx={{ fontSize: 20, color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    // noWrap
                    // component="a"
                    // href="/"
                    sx={{
                      m: 1,
                      display: "flex",
                      fontSize: 16,
                      color: "#fff",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/")}
                  >
                    Dashboard
                  </Typography>

                  <Typography
                    variant="h6"
                    // noWrap
                    // component="a"
                    // href="/profile"
                    sx={{
                      m: 1,
                      display: "flex",
                      fontSize: 16,
                      color: "#fff",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/profile")}
                  >
                    Profile
                  </Typography>

                  <Typography
                    variant="h6"
                    // noWrap
                    // component="a"
                    // href="/product"
                    sx={{
                      m: 1,
                      display: "flex",
                      fontSize: 16,
                      color: "#fff",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/product")}
                  >
                    Product
                  </Typography>

                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    disableElevation
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    endIcon={<KeyboardArrowDown />}
                    sx={{
                      textTransform: "capitalize",
                      padding: 0,
                      m: 1,
                      fontSize: 16,
                      color: "#fff",
                    }}
                  >
                    Delivery
                  </Button>

                  {/* <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/delivery"
                    sx={{
                      m: 1,
                      mr: 2,
                      display: "flex",
                      fontSize: 16,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    Delivery
                  </Typography> */}
                </>
              )}

              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    navigate("/delivery");
                  }}
                  disableRipple
                >
                  <AddToQueueIcon />
                  Add New
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    navigate("/delivery-record");
                  }}
                  disableRipple
                >
                  <AllInboxIcon />
                  Records
                </MenuItem>
              </StyledMenu>

              {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {anchorElUser ? <ExpandLessIcon sx={{ fontSize: 20, color: "#fff" }} /> : <ExpandMoreIcon sx={{ fontSize: 20, color: "#fff" }} />}
              </IconButton> */}

              <Tooltip title="Notification">
                <IconButton
                  sx={{ display: "flex" }}
                  onClick={handleOpenBellMenu}
                >
                  <StyledBadge badgeContent={productNotView} color="secondary">
                    <NotificationsActiveIcon
                      sx={{ fontSize: 20, color: "#fff" }}
                    />
                  </StyledBadge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Logout">
                <IconButton
                  sx={{ display: "flex" }}
                  // onClick={handleOpenBellMenu}
                  onClick={() => {
                    setLogoutDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Logout sx={{ fontSize: 20, color: "#fff" }} />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {route.map((data, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      navigate(data.path);
                      handleCloseUserMenu();
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ mr: 1 }}>{data.icon}</Box>
                      <Typography
                        textAlign="left"
                        variant="subtitle2"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}

                <MenuItem
                  onClick={() => {
                    setLogoutDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ mr: 1 }}>
                      <ExitToApp fontSize="small" />
                    </Box>
                    <Typography
                      textAlign="right"
                      variant="subtitle2"
                      sx={{ fontWeight: 700 }}
                    >
                      Log Out
                    </Typography>
                  </Box>
                </MenuItem>
              </Menu>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElBell}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElBell)}
                onClose={handleCloseBellMenu}
              >
                {/* {console.log("merchantProducts", merchantProducts)} */}
                {merchantProducts.data.length > 0 ? (
                  <Box sx={{ paddingX: "10px" }}>
                    <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
                      Notifications
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box flexGrow={1} />
                      <Box>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            height: 20,
                            fontWeight: 700,
                          }}
                          onClick={() => {
                            navigate("/merchant/orders");
                            handleCloseBellMenu();
                          }}
                        >
                          See All
                        </Button>
                      </Box>
                    </Box>

                    {merchantProducts.data.map((data, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setSelectedOrder(data);
                          setViewOrder(true);
                          handleCloseBellMenu();
                        }}
                        sx={{ width: "360px" }}
                      >
                        <Box sx={{ mr: 2 }}>
                          <AddBusinessIcon />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box flexGrow={1}>
                            <Typography
                              textAlign="left"
                              variant="subtitle2"
                              sx={{ fontWeight: 700 }}
                            >
                              {data.transactionID.replace("t-", "")}
                            </Typography>
                            <Typography
                              textAlign="left"
                              variant="subtitle2"
                              sx={{
                                fontWeight: 700,
                                color:
                                  parseInt(data.is_viewed) === 0
                                    ? "blue"
                                    : "none",
                              }}
                            >
                              {moment(data.created_at).fromNow()}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display:
                                parseInt(data.is_viewed) === 0
                                  ? "flex"
                                  : "none",
                              color:
                                parseInt(data.is_viewed) === 0
                                  ? "blue"
                                  : "none",
                            }}
                          >
                            <FiberManualRecordIcon fontSize="small" />
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Box>
                ) : (
                  <Box sx={{ padding: 1 }}>
                    <Typography sx={{ color: "red" }}>
                      Notification is empty.
                    </Typography>
                  </Box>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6">
            <b>Logging out your account...</b>
          </Typography>
          <Typography variant="caption">
            <i>Do you really want to logout your account?</i>
          </Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              onClick={() => {
                setLogoutDialog(false);
              }}
              sx={{ mr: 1, backgroundColor: "#AEAEAE" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // onClick={() => {
              //   localStorage.removeItem("estherToken");
              //   setLogoutDialog(false)
              //   setTimeout(() => {
              //     navigate("/");
              //     navigate(0);
              //   }, 1000);
              // }}
              onClick={() => {
                handleLogoutUser();
                setTimeout(() => {
                  navigate("/");
                  navigate(0);
                }, 1000);
              }}
              // disabled={isProcess}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={viewOrder}
        onClose={() => setViewOrder(false)}
        maxWidth="xs"
        fullWidth
      >
        <OrderDetails
          selectedOrder={selectedOrder}
          close={() => {
            setViewOrder(false);
          }}
          route="order"
        />
      </Dialog>
    </Box>
  );
};
export default MerchantAppbar;
