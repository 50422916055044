import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button, CssBaseline, Dialog } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { UserInfo } from "../components/context";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import axios from "axios";

const RiderAppbar = ({ module, route }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { userData, handleLogoutUser } = React.useContext(UserInfo);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  // const [isProcess, setIsProcess] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // console.log("userData.data", userData.data.id)

  // const handleLogoutRider = () => {
  //   var formdata = new FormData();
  //   formdata.append("rider_id", userData.data.id);
  //   setIsProcess(true);
  //   setLogoutDialog(false);
  //   axios
  //     .post("rider/logout", formdata)
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.message === "success") {
  //         setIsProcess(false);
  //         localStorage.removeItem("estherToken");
  //         setTimeout(() => {
  //           navigate("/");
  //           navigate(0);
  //         }, 1000);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsProcess(false);
  //     });
  // };

  React.useEffect(() => {}, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        // elevation={1}
        elevation={0}
        position="static"
        sx={{ backgroundColor: "#fff", color: "#000", minHeight: "64px" }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: "flex",
                fontFamily: "Futura Md Bt",
                fontSize: 28,
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {process.env.REACT_APP_APP_NAME === "estherph"
                ? "Esther PH"
                : "Hatood"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                noWrap
                component="a"
                sx={{
                  // mr: 0.5,
                  display: "flex",
                  fontFamily: "Futura Md Bt",
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleOpenUserMenu}
              >
                {userData.data.name}
              </Typography>

              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {anchorElUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {route.map((data, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      navigate(data.path);
                      handleCloseUserMenu();
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ mr: 1 }}>{data.icon}</Box>
                      <Typography
                        textAlign="left"
                        variant="subtitle2"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}

                <MenuItem
                  onClick={() => {
                    setLogoutDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ mr: 1 }}>
                      <ExitToApp fontSize="small" />
                    </Box>
                    <Typography
                      textAlign="right"
                      variant="subtitle2"
                      sx={{ fontWeight: 700 }}
                    >
                      Log Out
                    </Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6">
            <b>Logging out your account...</b>
          </Typography>
          <Typography variant="caption">
            <i>Do you really want to logout your account?</i>
          </Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              onClick={() => {
                setLogoutDialog(false);
              }}
              sx={{ mr: 1, backgroundColor: "#AEAEAE" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleLogoutUser();
                setTimeout(() => {
                  navigate("/");
                  navigate(0);
                }, 1000);
              }}
              // disabled={isProcess}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
export default RiderAppbar;
