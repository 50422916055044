import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import Container from "../../../layouts/Container";
import { CustomerInfo, UserInfo } from "../../context";
import UserCredentials from "./UserCredentials";
import UserDeleteAccount from "./UserDeleteAccount";
import UserInfoForm from "./UserInfoForm";
import UserLocation from "./UserLocation";
import UserPaymentMethod from "./UserPaymentMethod";

const CustomerProfile = () => {
  const { userInfo } = useContext(CustomerInfo);
  const { userData } = useContext(UserInfo);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //   border: "1px solid blue",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "1200px",
            maxWidth: "1200px",
            // border: "1px solid red",
            paddingX: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingY: "30px",
              maxWidth: "500px",
              //   border: "1px solid green",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <UserLocation userInfo={userInfo} userData={userData} />
              <UserInfoForm userInfo={userInfo} userData={userData} />
              <UserCredentials userInfo={userInfo} userData={userData} />
              <UserPaymentMethod userInfo={userInfo} />
              <UserDeleteAccount userInfo={userInfo} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "64px",
          backgroundColor: "#131418",
          bottom: 0,
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: 14 }}>
          &copy; 2022 {process.env.REACT_APP_APP_NAME}. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default CustomerProfile;
