import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { UserInfo } from "../../../../context";
import Close from "@mui/icons-material/Close";

const EditCategory = ({ getCategoryList, close, merchant_id, data }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);

  const handleEditCategory = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.set("id", data && data?.id !== null ? data?.id : "");
    formdata.set("merchant_id", merchant_id);
    setIsProcess(true);
    close();
    axios
      .post("user/merchant/update/category", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully update category");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getCategoryList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <Box sx={{ m: 2 }}>
      <form onSubmit={handleEditCategory}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>Edit Category</Typography>
          </Box>
          <IconButton size="small" onClick={() => close()}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Category"
            fullWidth
            name="category"
            defaultValue={data && data?.category !== null ? data?.category : ""}
            required
          />
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box flexGrow={1} />
          <Box>
            <Button
              variant="contained"
              size="large"
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                // fontSize: 12,
                paddingX: 2,
                backgroundColor:
                  process.env.REACT_APP_APP_NAME === "estherph"
                    ? "#261138"
                    : "#ff9f1d",
              }}
              type="submit"
              disabled={isProcess}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EditCategory;
