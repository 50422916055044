import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { UserInfo } from "../../context";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  Divider,
  Hidden,
  IconButton,
  InputAdornment,
  Snackbar,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Close, Search } from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import PendingIcon from "@mui/icons-material/Pending";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { DropzoneArea } from "react-mui-dropzone";
import VerifiedIcon from "@mui/icons-material/Verified";
import QrReader from "react-qr-reader";
import { isMobile } from "react-device-detect";

const MerchantCollection = () => {
  const {
    userData,
    setIsProcess,
    isProcess,
    setMessage,
    setSeverity,
    setOpenSnackbar,
  } = React.useContext(UserInfo);
  const [paymentDialog, setPaymentDialog] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const [totalBalance, setTotalBalance] = React.useState(0);
  const [paymentCategory, setPaymentCategory] = React.useState("");
  const [merchantPayments, setMerchantPayments] = React.useState({
    data: [],
    ready: false,
  });
  const [image, setImage] = React.useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openScanDialog, setOpenScanDialog] = React.useState(false);
  const [selectedPaymentID, setSelectedPaymentID] = React.useState(null);
  const [merchantPerItem, setMerchantPerItem] = React.useState(0);

  let totalPayments = 0;
  let totalPaymentsPending = 0;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchable = merchantPayments.data.filter((data) => {
    return (
      data.cp_id.replace("c-", "").toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  const calculate = (amount, status) => {
    if (status === "pending") {
      totalPaymentsPending =
        parseFloat(totalPaymentsPending) + parseFloat(amount);
    } else {
      totalPayments = parseFloat(totalPayments) + parseFloat(amount);
    }
    return amount;
  };

  const handleFilter = () => {
    var params = {
      user_id: userData.data.id,
      date_from: dateFrom,
      date_to: dateTo,
      query: "filter",
    };
    var error = [];
    if (dateFrom > dateTo) {
      error = "error";
      setIsProcess(false);
      setMessage("Invalid inputed dates.");
      setSeverity("error");
      setTimeout(() => {
        setOpenSnackbar(true);
      }, 500);
    }
    if (error.length > 0) {
      console.log("Something went wrong.");
    } else {
      setIsProcess(true);
      axios
        .get("user/merchant/get/payments", { params })
        .then((response) => {
          const data = response.data;
          setOpenFilter(false);
          setIsProcess(false);

          setOpenSnackbar(true);
          setMessage("Successfully filter record.");
          setSeverity("success");

          setMerchantPayments({ data, ready: true });
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("Error: ", error);
        });
    }
  };

  const getMerchantPayments = () => {
    var params = { user_id: userData.data.id, query: "get" };
    axios
      .get("user/merchant/get/payments", { params })
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setMerchantPayments({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  };

  const getMerchantOrdersFromCustomer = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("user/merchant/get/balance-collection", { params })
      .then((response) => {
        const data = response.data;
        setTotalBalance(data[0].quantity !== null ? data[0].quantity : 0);
      })
      .catch((err) => console.log("err", err));
  };

  const getMerchantPerItem = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("user/merchant/get/per-item", { params })
      .then((response) => {
        const data = response.data;
        setMerchantPerItem(
          data[0].merchant_per_item !== null ? data[0].merchant_per_item : 0
        );
      })
      .catch((err) => console.log("err", err));
  };

  const handleNewPayment = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.append("merchant_id", userData.data.id);
    formdata.append("image", image.length > 0 ? image[0] : null);
    console.log("formdata", formdata);
    var error = [];
    if (paymentCategory === "online" && image.length < 1) {
      error = "error";
      setOpenSnack(true);
    }
    if (error.length > 0) {
      console.log("form has a problem!");
    } else {
      axios
        .post("user/merchant/create/new-payment", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setPaymentDialog(false);
            setIsProcess(false);
            getMerchantPayments();

            setOpenSnackbar(true);
            setMessage("Successfully added payment.");
            setSeverity("success");
          }
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("error", error);
        });
    }
  };

  const handleImageChangeNew = (files) => {
    setImage(files);
  };

  //webcam capture success
  const handleVerifyPickup = (result) => {
    if (result) {
      var formdata = new FormData();
      formdata.append("id", selectedPaymentID);
      formdata.append("uuid", result);
      axios
        .post("user/merchant/update/payment/verify-pickup", formdata)
        .then((response) => {
          const data = response.data;
          console.log("data", data);
          if (data === "success") {
            setOpenScanDialog(false);
            setIsProcess(false);
            setOpenSnackbar(true);

            setMessage("Successfully verify payment pick up.");
            setSeverity("success");
            getMerchantPayments();
          }
          if (data === "not-allowed") {
            setOpenScanDialog(false);
            setIsProcess(false);
            setOpenSnackbar(true);

            setMessage("Personnel is not allowed to pick up");
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("error", error);
        });
    }
  };

  //webcam capture error
  const handleErrorWebCam = (error) => {
    console.log(error);
  };

  React.useEffect(() => {
    getMerchantPayments();
    getMerchantOrdersFromCustomer();
    getMerchantPerItem();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          // maxWidth: "1165px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "1165px",
              mx: "15px",
            }}
          >
            <Box>
              <Typography variant="h5">Payments</Typography>
            </Box>
            <Box flexGrow={1} />
            <Hidden smDown>
              <Box sx={{ mr: 1 }}>
                <TextField
                  label="Search Collection ID"
                  variant="outlined"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </Box>
            </Hidden>

            <Box>
              <Tooltip title="Filter">
                <IconButton
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                >
                  <TodayIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Add Payment">
                <IconButton
                  onClick={() => {
                    setPaymentDialog(true);
                  }}
                >
                  <AccountBalanceWalletIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
            paddingX: "15px",
          }}
        >
          {merchantPayments.data.length > 0 &&
            searchable.map((data, index) => (
              <Box
                sx={{
                  borderTop: "1px solid #AEAEAE",
                  borderLeft: "1px solid #AEAEAE",
                  borderBottom: "1px solid #AEAEAE",
                  borderRight:
                    data.payment_status === "complete"
                      ? "4px solid green"
                      : "4px solid red",
                  borderRadius: 2,
                  width: "100%",
                  maxWidth: "1165px",
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  mb: 1,
                }}
                key={index}
              >
                <Box sx={{ mr: 2 }}>
                  {data.payment_status === "complete" ? (
                    <TaskAltIcon sx={{ fontSize: "30px", color: "#50C878" }} />
                  ) : (
                    <PendingIcon sx={{ fontSize: "30px", color: "red" }} />
                  )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    sx={{ fontWeight: 700 }}
                  >
                    {data.cp_id.replace("c-", "")}
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    {moment(data.created_at).format("MMMM DD, YYYY")}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box
                  sx={{
                    display: isMobile ? "none" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 120,
                  }}
                >
                  <Chip
                    label={
                      data.payment_category === "online"
                        ? `${data.payment_category}(GCash)`
                        : `${data.payment_category}(pick-up)`
                    }
                    color="success"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 170,
                  }}
                >
                  <Typography gutterBottom variant="caption">
                    Amount: &#8369;
                    {`${parseFloat(data.amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </Typography>
                  <span hidden>
                    {calculate(data.amount, data.payment_status)}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: data.admin_id === null ? 170 : 30,
                  }}
                >
                  {data.payment_category === "cash" &&
                  data.admin_id === null ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenScanDialog(true);
                        setSelectedPaymentID(data.id);
                      }}
                    >
                      Verify Pick Up
                    </Button>
                  ) : (
                    <Tooltip title="Verified">
                      <VerifiedIcon sx={{ color: "green" }} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            ))}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "1165px",
            }}
          >
            <Box flexGrow={1} />
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={searchable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "1165px",
              mx: "15px",
            }}
          >
            {/* <Box flexGrow={1}>{merchantPayments.data.length} record(s) found</Box> */}
            <Box flexGrow={1} />
            <Box>
              <Typography
                variant="caption"
                sx={{ color: "green", fontWeight: 700 }}
              >
                Total Payment(s) :{" "}
                <span style={{ fontSize: "18px" }}>
                  &#8369;
                  {`${parseFloat(totalPayments).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "1165px",
              mx: "15px",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <Typography
                variant="caption"
                sx={{ color: "red", fontWeight: 700 }}
              >
                Total Pending Payment(s) :{" "}
                <span style={{ fontSize: "18px" }}>
                  &#8369;
                  {`${parseFloat(totalPaymentsPending).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )}`}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "1165px",
              mx: "15px",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <Typography variant="caption" sx={{ fontWeight: 700 }}>
                Current Balance(s) :{" "}
                <span style={{ fontSize: "18px" }}>
                  &#8369;
                  {`${(
                    parseFloat(totalBalance) * parseFloat(merchantPerItem) -
                    parseFloat(totalPayments)
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </span>
              </Typography>
              {/* <Typography variant="caption" sx={{ fontWeight: 700 }}>
                                Current Balance(s) : <span style={{ fontSize: '18px' }}>&#8369;{`${(parseFloat(totalBalance) - parseFloat(totalPayments)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span>
                            </Typography> */}
            </Box>
          </Box>
        </Box>

        {/* qr scanner dialog */}
        <Dialog
          open={openScanDialog}
          // open={true}
          onClose={() => setOpenScanDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <QrReader
            delay={2000}
            onError={handleErrorWebCam}
            onScan={handleVerifyPickup}
            facingMode={isMobile ? "environment" : "user"}
          />
        </Dialog>

        {/* payment dialog */}
        <Dialog
          open={paymentDialog}
          onClose={() => setPaymentDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 700 }}>Add Payment</Typography>
              </Box>
              <Box>
                <IconButton onClick={() => setPaymentDialog(false)}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box>
              <form onSubmit={handleNewPayment}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                        // fontFamily: "Futura Md Bt",
                        fontWeight: 700,
                      },
                      "& .MuiInput-input": {
                        borderRadius: "8px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        // fontSize: 14,
                      },
                      "& label + .MuiInput-root-emKjRF": {
                        marginTop: "18px",
                      },
                    }}
                    InputProps={{
                      disableunderline: "true",
                    }}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Payment Category"
                    fullWidth
                    size="small"
                    name="payment_category"
                    value={paymentCategory}
                    onChange={(e) => setPaymentCategory(e.target.value)}
                    required
                    multiline
                  >
                    <option value=""> --Select category-- </option>
                    <option value="cash">Cash To Pick Up</option>
                    <option value="online">Online Payment</option>
                  </TextField>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                        // fontFamily: "Futura Md Bt",
                        fontWeight: 700,
                      },
                      "& .MuiInput-input": {
                        borderRadius: "8px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        fontSize: 14,
                      },
                      "& label + .MuiInput-root-emKjRF": {
                        marginTop: "18px",
                      },
                    }}
                    InputProps={{
                      disableunderline: "true",
                      inputProps: {
                        min: 1,
                        max: 99999999999,
                        step: 0.01,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      parseFloat(totalBalance) * parseFloat(merchantPerItem) -
                      parseFloat(totalPayments)
                    }
                    label="Amount"
                    fullWidth
                    size="small"
                    name="amount"
                    required
                    type="number"
                  />
                </Box>

                {paymentCategory === "online" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography sx={{ color: "blue" }}>
                        Here's our GCASH 0916 264 6505, Please pay first then
                        send the attachments here.
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <DropzoneArea
                        onChange={handleImageChangeNew}
                        acceptedFiles={["image/jpeg", "image/png"]}
                        dropzoneText={"Upload payment screenshot"}
                        maxFileSize={2000000}
                        // style={{ maxHeight: "20px", border: "1px solid red" }}
                        filesLimit={1}
                        dropzoneClass={"dropZoneCustom"}
                        dropzoneParagraphClass={"dropZoneCustomText"}
                      />
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor:
                          process.env.REACT_APP_APP_NAME === "estherph"
                            ? "#261138"
                            : "#ff9f1d",
                      }}
                      type="submit"
                      disabled={
                        isProcess ||
                        parseFloat(totalBalance) - parseFloat(totalPayments) ===
                          0
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Dialog>

        {/* filter dialog */}
        <Dialog
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          maxWidth="xs"
          fullWidth
        >
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 700 }}>
                  Filter Record
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={() => setOpenFilter(false)}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <DatePicker
                // disableFuture
                label="Date From"
                openTo="year"
                views={["year", "month", "day"]}
                value={dateFrom}
                onChange={(newValue) => {
                  setDateFrom(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <DatePicker
                // disableFuture
                label="Date To"
                openTo="year"
                views={["year", "month", "day"]}
                value={dateTo}
                onChange={(newValue) => {
                  setDateTo(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>

            <Box sx={{ display: "flex", mt: 2 }}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#1976d2",
                  }}
                  onClick={handleFilter}
                  disabled={isProcess}
                >
                  Filter
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              setOpenSnack(false);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            Image of the product is required
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};
export default MerchantCollection;
