import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button, CardMedia, CssBaseline, Dialog, Tooltip } from "@mui/material";
import { ExitToApp, Home, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { isMobile } from "react-device-detect";
import axios from "axios";

const img1 =
  process.env.REACT_APP_APP_NAME === "estherph"
    ? require("./../assets/socialmediaicon/esther.png")
    : require("./../assets/socialmediaicon/hatood.png");

const ProprietorAppbar = ({ module, route }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutOther = () => {
    var formdata = new FormData();
    setIsProcess(true);
    setLogoutDialog(false);
    axios
      .post("other/logout", formdata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("estherToken")}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.message === "success") {
          setIsProcess(false);
          localStorage.removeItem("estherToken");
          setTimeout(() => {
            navigate("/");
            navigate(0);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  React.useEffect(() => {}, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="static"
        sx={{
          backgroundColor:
            process.env.REACT_APP_APP_NAME === "estherph"
              ? "#261138"
              : "#ff9f1d",
          color: "#000",
          minHeight: "64px",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <CardMedia
              component="img"
              src={`${img1}`}
              alt=""
              sx={{ cursor: "pointer", width: 50 }}
              onClick={() => {
                // window.location.href = "/";
                navigate("/");
              }}
            />
            {!isMobile && (
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  ml: 1,
                  display: "flex",
                  fontSize: 18,
                  color: "#fff",
                  textDecoration: "none",
                }}
              >
                {process.env.REACT_APP_APP_NAME === "estherph"
                  ? "Esther PH"
                  : "Hatood"}
              </Typography>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isMobile ? (
                <>
                  <Tooltip title="Dashboard">
                    <IconButton
                      sx={{
                        display: "flex",
                      }}
                      onClick={() => navigate("/")}
                    >
                      <Home sx={{ fontSize: 20, color: "#fff" }} />
                    </IconButton>
                  </Tooltip>

                  {/* <Tooltip title="Profile">
                                        <IconButton
                                            sx={{
                                                display: "flex",
                                            }}
                                            onClick={() => navigate("/profile")}
                                        >
                                            <ManageAccounts sx={{ fontSize: 20, color: "#fff" }} />
                                        </IconButton>
                                    </Tooltip> */}

                  {/* <Tooltip title="Product">
                                        <IconButton
                                            sx={{
                                                display: "flex",
                                                mr: 2,
                                            }}
                                            onClick={() => navigate("/product")}
                                        >
                                            <Inventory2 sx={{ fontSize: 20, color: "#fff" }} />
                                        </IconButton>
                                    </Tooltip> */}
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      m: 1,
                      display: "flex",
                      fontSize: 16,
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    Dashboard
                  </Typography>

                  {/* <Typography
                                        variant="h6"
                                        noWrap
                                        component="a"
                                        href="/profile"
                                        sx={{
                                            m: 1,
                                            display: "flex",
                                            fontSize: 16,
                                            color: "#fff",
                                            textDecoration: "none",
                                        }}
                                    >
                                        Profile
                                    </Typography> */}
                </>
              )}

              <Tooltip title="Logout">
                <IconButton
                  sx={{ display: "flex" }}
                  onClick={() => {
                    setLogoutDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Logout sx={{ fontSize: 20, color: "#fff" }} />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {route.map((data, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      navigate(data.path);
                      handleCloseUserMenu();
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ mr: 1 }}>{data.icon}</Box>
                      <Typography
                        textAlign="left"
                        variant="subtitle2"
                        sx={{ fontWeight: 700 }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}

                <MenuItem
                  onClick={() => {
                    setLogoutDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ mr: 1 }}>
                      <ExitToApp fontSize="small" />
                    </Box>
                    <Typography
                      textAlign="right"
                      variant="subtitle2"
                      sx={{ fontWeight: 700 }}
                    >
                      Log Out
                    </Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 3 }}>
          <Typography variant="h6">
            <b>Logging out your account...</b>
          </Typography>
          <Typography variant="caption">
            <i>Do you really want to logout your account?</i>
          </Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              onClick={() => {
                setLogoutDialog(false);
              }}
              sx={{ mr: 1, backgroundColor: "#AEAEAE" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // onClick={() => {
              //     localStorage.removeItem("estherToken");
              //     setLogoutDialog(false)
              //     setTimeout(() => {
              //         navigate("/");
              //         navigate(0);
              //     }, 1000);
              // }}
              onClick={() => {
                handleLogoutOther();
              }}
              disabled={isProcess}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
export default ProprietorAppbar;
