import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { UserInfo } from "../../../context";
import Close from "@mui/icons-material/Close";

const ConversionForm = ({
  handleFetchConversionList,
  close,
  selectedConfig,
}) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [to_unit_qty, set_to_unit_qty] = useState("");

  const handleNewConversion = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("product_id", selectedConfig && selectedConfig.id);
    setIsProcess(true);
    close();
    axios
      .post("user/merchant/create/new-conversion", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully added conversion");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              handleFetchConversionList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <form onSubmit={handleNewConversion} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography sx={{ fontWeight: 700 }}>New Conversion</Typography>
            </Box>
            <IconButton size="small" onClick={() => close()}>
              <Close />
            </IconButton>
          </Box>

          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <TextField
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Name"
              fullWidth
              name="product"
              required
              multiline
              value={
                selectedConfig && selectedConfig.product
                  ? selectedConfig.product
                  : ""
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Unit"
              fullWidth
              name="unit"
              required
              multiline
              value={
                selectedConfig && selectedConfig.unit ? selectedConfig.unit : ""
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="Unit for conversion"
              fullWidth
              name="to_unit"
              required
              multiline
              onChange={(e) => set_to_unit_qty(e.target.value)}
            />
          </Box>

          {to_unit_qty !== "" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <TextField
                InputProps={{
                  disableunderline: "true",
                  inputProps: {
                    min: 1,
                    max: 99999999999,
                    step: 1,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={`How many ${
                  selectedConfig && selectedConfig.unit
                } in 1 ${to_unit_qty}`}
                fullWidth
                name="to_unit_qty"
                required
                type="number"
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                size="large"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: 2,
                  //   fontSize: 12,
                  paddingX: 2,
                  backgroundColor:
                    process.env.REACT_APP_APP_NAME === "estherph"
                      ? "#261138"
                      : "#ff9f1d",
                }}
                type="submit"
                disabled={isProcess}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ConversionForm;
