import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Add, Close, Edit, Search } from "@mui/icons-material";
import { UserInfo } from "../../context";
import barangays from "../../../assets/barangay.json";
import cities from "../../../assets/city.json";
import provinces from "../../../assets/province.json";
import { Map, TileLayer, LayersControl, Marker } from "react-leaflet";
import Control from "react-leaflet-control";
import L from "leaflet";
import iconRetina from "./../../../assets/opensourcemap/marker-icon-2x.png";
import icon from "./../../../assets/opensourcemap/marker-icon.png";
import iconShadow from "./../../../assets/opensourcemap/marker-shadow.png";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const StationList = ({ stationList, getStationList }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState(null);
  const [addStationDialog, setAddStationDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBarangay, setSelectedBarangay] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredBarangay, setFilteredBarangays] = useState([]);
  const selectRef = useRef(null);
  const mapRef = useRef();
  var refmarker2 = useRef(null);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [places, setPlaces] = useState("");
  const [marker, setmarker] = useState({
    lat: process.env.REACT_APP_BASE_LAT,
    lng: process.env.REACT_APP_BASE_LNG,
  });
  const [selectedLatLng, setSelectedLatLng] = useState({
    lat: null,
    lng: null,
  });

  const handleEditDragMarker = () => {
    setmarker({
      ...marker,
      lat: refmarker2.leafletElement.getLatLng().lat,
      lng: refmarker2.leafletElement.getLatLng().lng,
    });
    setSelectedLatLng({
      ...selectedLatLng,
      lat: refmarker2.leafletElement.getLatLng().lat,
      lng: refmarker2.leafletElement.getLatLng().lng,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getBarangayByCode = (code) => {
    const filtered = barangays.filter((brgy) => brgy.brgy_code === code);
    return filtered[0].brgy_name;
  };

  const getCityByCode = (code) => {
    const filtered = cities.filter((city) => city.city_code === code);
    return filtered[0].city_name;
  };

  const getProvinceByCode = (code) => {
    const filtered = provinces.filter((prov) => prov.province_code === code);
    return filtered[0].province_name;
  };

  useEffect(() => {
    if (selectedProvince) {
      const filtered = cities.filter(
        (city) => city.province_code === selectedProvince
      );
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]);
    }
    const inputElement = selectRef.current?.input?.input;
    if (inputElement) {
      inputElement.setAttribute("autocomplete", "off");
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedCity) {
      const filtered = barangays.filter(
        (brgy) => brgy.city_code === selectedCity
      );
      setFilteredBarangays(filtered);
    } else {
      setFilteredBarangays([]);
    }
    const inputElement = selectRef.current?.input?.input;
    if (inputElement) {
      inputElement.setAttribute("autocomplete", "off");
    }
  }, [selectedCity]);

  function getCoordinates() {
    setSelectedLatLng({ ...selectedLatLng, lat: null, lng: null });
    var map = mapRef.current.leafletElement;
    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${places}.json?access_token=pk.eyJ1IjoiZGVueDcyIiwiYSI6ImNqYzIwZnl5eDBjaHAycW9kZ2doZW1laTMifQ.A4FqSmhMUdx624cwM4fjvw`
      )
      .then((res) => {
        setmarker({
          ...marker,
          lat: res.data.features[0].geometry.coordinates[1],
          lng: res.data.features[0].geometry.coordinates[0],
        });
        map.setView(
          new L.LatLng(
            res.data.features[0].geometry.coordinates[1],
            res.data.features[0].geometry.coordinates[0]
          ),
          13
        );
        setOpenSearchDialog(false);
      });
  }

  const handleNewStation = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("latitude", selectedLatLng.lat);
    formdata.append("longitude", selectedLatLng.lng);

    setIsProcess(true);
    axios
      .post("user/admin/create/new-station", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully added new station");
          setSeverity("success");
          setOpenSnackbar(true);
          setAddStationDialog(false);
          setIsProcess(false);
          getStationList();
          setSelectedLatLng({ ...selectedLatLng, lat: null, lng: null });
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  const handleEditStation = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", selectedData !== null && selectedData.id);
    formdata.append(
      "latitude",
      selectedLatLng.lat !== null
        ? selectedLatLng.lat
        : selectedData !== null && selectedData.latitude
    );
    formdata.append(
      "longitude",
      selectedLatLng.lng !== null
        ? selectedLatLng.lng
        : selectedData !== null && selectedData.longitude
    );

    setIsProcess(true);
    axios
      .post("user/admin/update/station", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully updated station");
          setSeverity("success");
          setOpenSnackbar(true);
          setEditDialog(false);
          setIsProcess(false);
          getStationList();

          //validation
          setSelectedLatLng({ ...selectedLatLng, lat: null, lng: null });
          setSelectedProvince("");
          setSelectedCity("");
          setSelectedBarangay("");
          setmarker({
            ...marker,
            lat: process.env.REACT_APP_BASE_LAT,
            lng: process.env.REACT_APP_BASE_LNG,
          });
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex" }}>
        <Box flexGrow={1} />
        <Box>
          <Tooltip title="Add Station">
            <IconButton
              onClick={() => {
                setSelectedProvince("");
                setSelectedCity("");
                setSelectedBarangay("");
                setmarker({
                  ...marker,
                  lat: process.env.REACT_APP_BASE_LAT,
                  lng: process.env.REACT_APP_BASE_LNG,
                });
                setAddStationDialog(true);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Address</b>
              </TableCell>
              <TableCell align="center">
                <b>Battery Loaded</b>
              </TableCell>
              <TableCell align="center">
                <b>Battery Empty</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {stationList.ready && stationList.data.length > 0 ? (
              stationList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.station_name}</TableCell>
                  <TableCell>
                    {data.street}, {getBarangayByCode(data.barangay)},{" "}
                    {getCityByCode(data.city)},{" "}
                    {getProvinceByCode(data.province)}
                  </TableCell>
                  <TableCell align="right">{data.loaded_battery}</TableCell>
                  <TableCell align="right">{data.empty_battery}</TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Edit Station">
                        <IconButton
                          onClick={() => {
                            setSelectedData(data);
                            setmarker({
                              ...marker,
                              lat: data.latitude,
                              lng: data.longitude,
                            });
                            setSelectedProvince(data.province);
                            setSelectedCity(data.city);
                            setSelectedBarangay(data.barangay);
                            setEditDialog(true);
                          }}
                          // disabled={module === 'admin' && parseInt(allowAccount.allow) === 0 ? true : false}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No station added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={stationList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* ADD NEW STATION */}
      <Dialog
        open={addStationDialog}
        onClose={() => setAddStationDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Add Station</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddStationDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <form onSubmit={handleNewStation}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 1,
                }}
              >
                <TextField
                  InputProps={{
                    disableunderline: "true",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Station Name"
                  fullWidth
                  name="station_name"
                  required
                  size="small"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  mb: 1,
                }}
              >
                <TextField
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Province"
                  name="province"
                  required
                  // value={inputedCity}
                  onChange={(e) => {
                    setSelectedProvince(e.target.value);
                  }}
                  ref={selectRef}
                  // margin="dense"
                  size="small"
                >
                  <option value=""> - Select province - </option>
                  {provinces.map((province) => (
                    <option
                      key={province.province_code}
                      value={province.province_code}
                    >
                      {province.province_name}
                    </option>
                  ))}
                </TextField>
              </Box>

              {selectedProvince !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="City/Municipality"
                    name="city"
                    required
                    // value={inputedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    ref={selectRef}
                    // margin="dense"
                    size="small"
                  >
                    <option value=""> - Select city - </option>
                    {filteredCities.map((city) => (
                      <option key={city.city_code} value={city.city_code}>
                        {city.city_name}
                      </option>
                    ))}
                  </TextField>
                </Box>
              )}

              {selectedProvince !== "" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <TextField
                      fullWidth
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Barangay"
                      name="barangay"
                      required
                      ref={selectRef}
                      // margin="dense"
                      size="small"
                    >
                      <option value=""> - Select category - </option>
                      {filteredBarangay.map((brgy) => (
                        <option key={brgy.brgy_code} value={brgy.brgy_code}>
                          {brgy.brgy_name}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Business Street | Bldg."
                      fullWidth
                      placeholder="Business Street | Bldg."
                      name="street"
                      required
                      // margin="dense"
                      size="small"
                    />
                  </Box>

                  <Box mb={1}>
                    <Map
                      style={{
                        width: "100%",
                        height: "300px",
                      }}
                      zoom={13}
                      center={[
                        process.env.REACT_APP_BASE_LAT,
                        process.env.REACT_APP_BASE_LNG,
                      ]}
                      ref={mapRef}
                    >
                      <Control position="topleft">
                        <Box
                          style={{
                            width: 30,
                            height: 30,
                            marginLeft: 2,
                            // border: "1px solid rgba(0,0,0,0.2)",
                            borderRadius: 4,
                            backgroundColor: "#fff",
                            color: "#000",
                            //   borderColor: "#AEAEAE",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenSearchDialog(true)}
                        >
                          <Search />
                        </Box>
                      </Control>

                      {/* <FullscreenControl position="topright" /> */}

                      <LayersControl position="topright" style={{ width: 200 }}>
                        <LayersControl.BaseLayer checked name="Streets">
                          <TileLayer
                            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url={
                              "https://api.mapbox.com/styles/v1/infimaxhp888/ckgalpab809dn19pm9gd1ajhp/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5maW1heGhwODg4IiwiYSI6ImNqeXZjOHNpazA5aW4zZHBvdDBhd3NiOWQifQ._czEMv00RYK5VQHXR0Sk2w"
                            }
                            maxZoom={20}
                            minZoom={5}
                            crossOrigin={true}
                          />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Satellite">
                          <TileLayer
                            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url={
                              "https://api.mapbox.com/styles/v1/infimaxhp888/ckotweguz1lll18lnugoq4g6j/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5maW1heGhwODg4IiwiYSI6ImNqeXZjOHNpazA5aW4zZHBvdDBhd3NiOWQifQ._czEMv00RYK5VQHXR0Sk2w"
                            }
                            maxZoom={20}
                            minZoom={5}
                            crossOrigin={true}
                          />
                        </LayersControl.BaseLayer>
                      </LayersControl>

                      <Marker
                        position={marker}
                        draggable={true}
                        onDragend={() => handleEditDragMarker()}
                        ref={(ref) => (refmarker2 = ref)}
                      />
                    </Map>
                  </Box>
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    // size="small"
                    sx={{
                      //   textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      paddingX: 2,
                      backgroundColor:
                        isProcess ||
                        selectedLatLng.lat === null ||
                        selectedLatLng.lng === null
                          ? "#e5e5e5"
                          : "#d65d46",
                    }}
                    type="submit"
                    disabled={
                      isProcess ||
                      selectedLatLng.lat === null ||
                      selectedLatLng.lng === null
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>

      {/* CITY SEARCH */}
      <Dialog
        open={openSearchDialog}
        onClose={() => setOpenSearchDialog(false)}
      >
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          m={2}
        >
          <Box sx={{ mr: 1 }}>
            <TextField
              placeholder="Search for places.."
              onChange={(e) => setPlaces(e.target.value)}
              size="small"
            />
          </Box>
          <Box>
            <IconButton onClick={() => getCoordinates()}>
              <Search size={20} />
            </IconButton>
          </Box>
        </Box>
      </Dialog>

      {/* EDIT STATION */}
      <Dialog
        open={editDialog}
        onClose={() => setEditDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Edit Station</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setEditDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {selectedData !== null && (
              <form onSubmit={handleEditStation}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    InputProps={{
                      disableunderline: "true",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Station Name"
                    fullWidth
                    name="station_name"
                    required
                    size="small"
                    defaultValue={selectedData.station_name}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Province"
                    name="province"
                    required
                    defaultValue={selectedData.province}
                    onChange={(e) => {
                      setSelectedProvince(e.target.value);
                      setSelectedCity("");
                      setSelectedBarangay("");
                    }}
                    ref={selectRef}
                    size="small"
                  >
                    <option value=""> - Select province - </option>
                    {provinces.map((province) => (
                      <option
                        key={province.province_code}
                        value={province.province_code}
                      >
                        {province.province_name}
                      </option>
                    ))}
                  </TextField>
                </Box>

                {selectedProvince !== "" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    <TextField
                      fullWidth
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="City/Municipality"
                      name="city"
                      required
                      value={selectedCity}
                      onChange={(e) => {
                        setSelectedCity(e.target.value);
                        setSelectedBarangay("");
                      }}
                      ref={selectRef}
                      size="small"
                    >
                      <option value=""> - Select city - </option>
                      {filteredCities.map((city) => (
                        <option key={city.city_code} value={city.city_code}>
                          {city.city_name}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                )}

                {selectedCity !== "" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Barangay"
                        name="barangay"
                        required
                        value={selectedBarangay}
                        onChange={(e) => setSelectedBarangay(e.target.value)}
                        ref={selectRef}
                        size="small"
                      >
                        <option value=""> - Select category - </option>
                        {filteredBarangay.map((brgy) => (
                          <option key={brgy.brgy_code} value={brgy.brgy_code}>
                            {brgy.brgy_name}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Business Street | Bldg."
                        fullWidth
                        placeholder="Business Street | Bldg."
                        name="street"
                        required
                        size="small"
                        defaultValue={selectedData.street}
                      />
                    </Box>

                    <Box mb={1}>
                      <Map
                        style={{
                          width: "100%",
                          height: "300px",
                        }}
                        zoom={13}
                        center={[marker.lat, marker.lng]}
                        ref={mapRef}
                      >
                        <Control position="topleft">
                          <Box
                            style={{
                              width: 30,
                              height: 30,
                              marginLeft: 2,
                              borderRadius: 4,
                              backgroundColor: "#fff",
                              color: "#000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => setOpenSearchDialog(true)}
                          >
                            <Search />
                          </Box>
                        </Control>

                        {/* <FullscreenControl position="topright" /> */}

                        <LayersControl
                          position="topright"
                          style={{ width: 200 }}
                        >
                          <LayersControl.BaseLayer checked name="Streets">
                            <TileLayer
                              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url={
                                "https://api.mapbox.com/styles/v1/infimaxhp888/ckgalpab809dn19pm9gd1ajhp/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5maW1heGhwODg4IiwiYSI6ImNqeXZjOHNpazA5aW4zZHBvdDBhd3NiOWQifQ._czEMv00RYK5VQHXR0Sk2w"
                              }
                              maxZoom={20}
                              minZoom={5}
                              crossOrigin={true}
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Satellite">
                            <TileLayer
                              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url={
                                "https://api.mapbox.com/styles/v1/infimaxhp888/ckotweguz1lll18lnugoq4g6j/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5maW1heGhwODg4IiwiYSI6ImNqeXZjOHNpazA5aW4zZHBvdDBhd3NiOWQifQ._czEMv00RYK5VQHXR0Sk2w"
                              }
                              maxZoom={20}
                              minZoom={5}
                              crossOrigin={true}
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>

                        <Marker
                          position={marker}
                          draggable={true}
                          onDragend={() => handleEditDragMarker()}
                          ref={(ref) => (refmarker2 = ref)}
                        />
                      </Map>

                      {/* {console.log("MARKER: ", marker)} */}
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor: isProcess ? "#e5e5e5" : "#d65d46",
                      }}
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default StationList;
