import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  IconButton,
  Radio,
} from "@mui/material";
import axios from "axios";
import { UserInfo, MerchantInfo } from "../../context";
import { Search, LocationSearching } from "@mui/icons-material";
import {
  Map,
  TileLayer,
  // LayersControl,
  Marker,
} from "react-leaflet";
import Control from "react-leaflet-control";
import L from "leaflet";
import iconRetina from "./../../../assets/opensourcemap/marker-icon-2x.png";
import icon from "./../../../assets/opensourcemap/marker-icon.png";
import iconShadow from "./../../../assets/opensourcemap/marker-shadow.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { isMobile } from "react-device-detect";
import FullscreenControl from "react-leaflet-fullscreen";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const MerchantLocation = ({ userData }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const { reRunLocation, merchantInfo } = useContext(MerchantInfo);
  const [locationList, setLocationList] = useState({ data: [], ready: false });

  //new for map
  const [openEditLoc, setOpenEditLoc] = useState({
    open: false,
    latitude: null,
    longitude: null,
  });
  const mapRef = React.useRef();
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  var refmarker2 = React.useRef(null);
  const [marker, setmarker] = React.useState({
    lat: process.env.REACT_APP_BASE_LAT,
    lng: process.env.REACT_APP_BASE_LNG,
  });
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const handleEditDragMarker = () => {
    setOpenEditLoc({
      ...openEditLoc,
      latitude: refmarker2.leafletElement.getLatLng().lat,
      longitude: refmarker2.leafletElement.getLatLng().lng,
    });
  };

  function getCoordinates() {
    var map = mapRef.current.leafletElement;
    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${city}.json?access_token=pk.eyJ1IjoiZGVueDcyIiwiYSI6ImNqYzIwZnl5eDBjaHAycW9kZ2doZW1laTMifQ.A4FqSmhMUdx624cwM4fjvw`
      )
      .then((res) => {
        setmarker({
          ...marker,
          lat: res.data.features[0].geometry.coordinates[1],
          lng: res.data.features[0].geometry.coordinates[0],
        });
        map.setView(
          new L.LatLng(
            res.data.features[0].geometry.coordinates[1],
            res.data.features[0].geometry.coordinates[0]
          ),
          13
        );
        setOpenSearchDialog(false);
      });
  }

  const handleSaveLocation = () => {
    var formdata = new FormData();
    formdata.append("user_id", userData.data.id);
    formdata.append("address", address);
    formdata.append("latitude", openEditLoc.latitude);
    formdata.append("longitude", openEditLoc.longitude);

    if (openEditLoc.latitude === "" && openEditLoc.longitude === "") {
      setMessage("Move the marker the get your location");
      setSeverity("error");
      setOpenSnackbar(true);
    } else if (address === "") {
      setMessage("Enter place name");
      setSeverity("error");
      setOpenSnackbar(true);
    } else {
      setIsProcess(true);
      axios
        .post("user/create/location", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setOpenEditLoc({ ...openEditLoc, open: false });
            reRunLocation();
            setMessage("Successfully added location");
            setSeverity("success");
            setTimeout(() => {
              setIsProcess(false);
              setOpenSnackbar(true);
              setTimeout(() => {
                getUserLocation();
              }, 500);
            }, 1000);
          }
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("error", error);
        });
    }
  };

  const getUserLocation = () => {
    var params = { user_id: userData.data.id };
    axios
      .get("user/get/locations", { params })
      .then((response) => {
        const data = response.data;
        setLocationList({ data, ready: true });
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getUserLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (merchantInfo.ready && merchantInfo.data) {
      setAddress(
        merchantInfo.data?.business_name !== undefined
          ? merchantInfo.data && merchantInfo.data?.business_name
          : ""
      );
    }
  }, [merchantInfo]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography sx={{ fontWeight: 700 }}> My Location</Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Divider light />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        {locationList &&
          (locationList.data.length > 0 ? (
            locationList.data.map((data, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  border: "2px solid #AEAEAE",
                  borderStyle: "dashed",
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <LocationOnIcon
                    sx={{
                      mr: 2,
                      color: "#50C878",
                    }}
                    fontSize="small"
                  />
                  <Typography>{data.address}</Typography>
                </Box>
                <Box flexGrow={1} />
                <Box>
                  <Radio
                    checked={parseInt(data.status) === 1}
                    value="b"
                    name="list-radio"
                  />
                </Box>
              </Box>
            ))
          ) : (
            <Box sx={{ width: "100%" }}>
              <Typography
                align="center"
                sx={{
                  color: "red",
                  textAlign: "center",
                }}
              >
                No location added
              </Typography>
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box flexGrow={1} />
        <Box>
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              borderRadius: 2,
              fontSize: 12,
              paddingX: 2,
              backgroundColor:
                process.env.REACT_APP_APP_NAME === "estherph"
                  ? "#261138"
                  : "#ff9f1d",
            }}
            type="submit"
            disabled={isProcess || locationList.data.length > 0}
            onClick={() =>
              setOpenEditLoc({
                ...openEditLoc,
                open: true,
                latitude: "",
                longitude: "",
              })
            }
          >
            Add Location
          </Button>
        </Box>
      </Box>

      {/* map */}
      <Dialog
        open={openEditLoc.open}
        onClose={() => {
          setOpenEditLoc({
            ...openEditLoc,
            open: false,
          });
          setmarker({
            lat: process.env.REACT_APP_BASE_LAT,
            lng: process.env.REACT_APP_BASE_LNG,
          });
        }}
      >
        <Box
          mt={2}
          style={{
            width: isMobile ? "330px" : "430px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Map
            style={{
              width: "100%",
              maxWidth: isMobile ? "300px" : "400px",
              height: "400px",
            }}
            zoom={openEditLoc.latitude !== "" ? 13 : 5}
            center={[
              openEditLoc.latitude !== ""
                ? openEditLoc.latitude
                : process.env.REACT_APP_BASE_LAT,
              openEditLoc.longitude !== ""
                ? openEditLoc.longitude
                : process.env.REACT_APP_BASE_LNG,
            ]}
            ref={mapRef}
          >
            <Control position="topleft">
              <Box
                style={{
                  width: 34,
                  height: 34,
                  border: "2px solid rgba(0,0,0,0.2)",
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  color: "#000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setOpenSearchDialog(true)}
              >
                <Search />
              </Box>
              <Box
                style={{
                  width: 34,
                  height: 34,
                  border: "2px solid rgba(0,0,0,0.2)",
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  color: "#000",
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  display: isMobile ? "flex" : "none",
                }}
                onClick={() => {
                  var map = mapRef.current.leafletElement;
                  navigator.geolocation.getCurrentPosition(
                    function (position) {
                      setmarker({
                        ...marker,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                      });
                      map.setView(
                        new L.LatLng(
                          position.coords.latitude,
                          position.coords.longitude
                        ),
                        13
                      );
                      setOpenEditLoc({
                        ...openEditLoc,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                      });
                    },
                    function (error) {
                      alert(error.message);
                    },
                    {
                      enableHighAccuracy: true,
                      timeout: 5000,
                    }
                  );
                }}
              >
                <LocationSearching fontSize="small" />
              </Box>
            </Control>

            <FullscreenControl position="topright" />

            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={
                "https://api.mapbox.com/styles/v1/infimaxhp888/ckgalpab809dn19pm9gd1ajhp/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5maW1heGhwODg4IiwiYSI6ImNqeXZjOHNpazA5aW4zZHBvdDBhd3NiOWQifQ._czEMv00RYK5VQHXR0Sk2w"
              }
              maxZoom={20}
              minZoom={5}
              crossOrigin={true}
            />

            <Marker
              position={marker}
              draggable={true}
              onDragend={() => handleEditDragMarker()}
              ref={(ref) => (refmarker2 = ref)}
            />
          </Map>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            paddingX: 2,
          }}
        >
          <TextField
            sx={{
              "& .MuiInputLabel-root": {
                color: "#000",
                fontWeight: 700,
              },
              "& .MuiInput-input": {
                borderRadius: "8px",
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "14px",
                paddingRight: "14px",
                fontSize: 14,
              },
              "& label + .MuiInput-root-emKjRF": {
                marginTop: "18px",
              },
            }}
            InputProps={{
              disableunderline: "true",
              inputProps: {
                style: {
                  color: "#AEAEAE",
                  backgroundColor: "#fff",
                },
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Place Name"
            fullWidth
            size="small"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </Box>
        <Box sx={{ m: 2 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSaveLocation}
            disabled={isProcess}
          >
            Save location
          </Button>
        </Box>
      </Dialog>

      {/* map search */}
      <Dialog
        open={openSearchDialog}
        onClose={() => setOpenSearchDialog(false)}
      >
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          m={2}
        >
          <Box sx={{ mr: 1 }}>
            <TextField
              placeholder="Search for places.."
              onChange={(e) => setCity(e.target.value)}
              size="small"
            />
          </Box>
          <Box>
            <IconButton onClick={() => getCoordinates()}>
              <Search size={20} />
            </IconButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default MerchantLocation;
