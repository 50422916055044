import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import Container from "../../../layouts/Container";
// import { CustomerInfo, UserInfo } from "../../context";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import ForumIcon from "@mui/icons-material/Forum";

const CustomerHelpDesk = () => {
  //   const { userInfo } = useContext(CustomerInfo);
  //   const { userData } = useContext(UserInfo);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          //   border: "1px solid blue",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "1200px",
            maxWidth: "1200px",
            // border: "1px solid red",
            paddingX: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingY: "30px",
              maxWidth: "500px",
              //   border: "1px solid green",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "418px",
                    height: "410px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: 5,
                  }}
                >
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="h4" sx={{ fontWeight: 800 }}>
                      Need Help?
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 800 }}>
                      Having issues with a site, found a bug, or just need help
                      with something?
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ mr: 1 }}>
                        <HelpIcon fontSize="small" />
                      </Box>
                      <Box>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          Check out the documentation for answers to the most
                          common questions and instructions for the most common
                          tasks.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ mr: 1 }}>
                        <InfoIcon fontSize="small" />
                      </Box>
                      <Box>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          Visit the site troubleshooting page if you're having
                          access or connectivity issues with your site, or
                          {process.env.REACT_APP_APP_NAME} troubleshooting page
                          if you're having issues with{" "}
                          {process.env.REACT_APP_APP_NAME}.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ mr: 1 }}>
                        <ForumIcon fontSize="small" />
                      </Box>
                      <Box>
                        <Typography variant="caption" sx={{ fontWeight: 600 }}>
                          Still need help? No problem! Simply use only form to
                          get in touch with us directly.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "418px",
                    height: "410px",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f2f2f2",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 800 }}>
                      What we can do for you?
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                            fontFamily: "Futura Md Bt",
                            borderRadius: "8px",
                            paddingTop: "4px",
                            paddingBottom: "8px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            fontSize: 14,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#fff",
                          fontFamily: "Futura Md Bt",
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "23px",
                        },
                      }}
                      fullWidth
                      variant="standard"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedCategory !== null ? selectedCategory : ""}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value=""> - Select a category - </option>
                      <option value="category1">
                        I have an issue with one of my orders or reorders
                      </option>
                      <option value="category2">
                        I'm having trouble with my payment connectivity
                      </option>
                      <option value="category3">
                        I have an issue with my account
                      </option>
                      <option value="category4">
                        I have an issue with {process.env.REACT_APP_APP_NAME}
                      </option>
                      <option value="category5">I have a bug report</option>
                      <option value="category6">
                        I have a general question
                      </option>
                      <option value="category7">
                        I have a suggestion or feedback
                      </option>
                      <option value="category8">Others</option>
                    </TextField>
                  </Box>
                  {selectedCategory !== null && (
                    <>
                      <Box sx={{ mb: 2 }}>
                        <TextField
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "#000",
                              fontFamily: "Futura Md Bt",
                              fontWeight: 700,
                            },
                            "& .MuiInput-input": {
                              borderRadius: "8px",
                              paddingTop: "6px",
                              paddingBottom: "6px",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              fontSize: 14,
                            },
                            "& label + .MuiInput-root-emKjRF": {
                              marginTop: "18px",
                            },
                          }}
                          InputProps={{
                            disableunderline: "true",
                            inputProps: {
                              style: {
                                color: "#AEAEAE",
                                backgroundColor: "#fff",
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Subject"
                          variant="standard"
                          fullWidth
                          size="small"
                        />
                      </Box>

                      <Box sx={{ mb: 3 }}>
                        <TextField
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "#000",
                              fontFamily: "Futura Md Bt",
                              fontWeight: 700,
                            },
                            "& .MuiInput-input": {
                              borderRadius: "8px",
                              paddingTop: "6px",
                              paddingBottom: "6px",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              fontSize: 14,
                            },
                            "& label + .MuiInput-root-emKjRF": {
                              marginTop: "18px",
                            },
                          }}
                          InputProps={{
                            disableunderline: "true",
                            inputProps: {
                              style: {
                                color: "#AEAEAE",
                                backgroundColor: "#fff",
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          multiline
                          rows={4}
                          label="Message"
                          variant="standard"
                          fullWidth
                          size="small"
                        />
                      </Box>
                    </>
                  )}

                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor: "#d65d46",
                      }}
                    >
                      Send Feedback
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "64px",
          backgroundColor: "#131418",
          bottom: 0,
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: 14 }}>
          &copy; 2022 {process.env.REACT_APP_APP_NAME}. All rights reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default CustomerHelpDesk;
