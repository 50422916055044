import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UserInfo } from "../../context";
import {
  Button,
  Dialog,
  Divider,
  Hidden,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import moment from "moment";

const MerchantExpense = () => {
  const {
    userData,
    setIsProcess,
    isProcess,
    setMessage,
    setSeverity,
    setOpenSnackbar,
  } = React.useContext(UserInfo);
  const [search, setSearch] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  const [expenseDialog, setExpenseDialog] = React.useState(false);
  const [dateExpense, setDateExpense] = React.useState(new Date());
  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const [merchantExpense, setMerchantExpense] = React.useState({
    data: [],
    ready: false,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let totalExpenses = 0;

  const searchable = merchantExpense.data.filter((data) => {
    return data.expense_name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  const calculate = (amount) => {
    totalExpenses = parseFloat(totalExpenses) + parseFloat(amount);
    return amount;
  };

  const handleFilter = () => {
    var params = {
      merchant_id: userData.data.id,
      date_from: dateFrom,
      date_to: dateTo,
    };
    var error = [];
    if (dateFrom > dateTo) {
      error = "error";
      setIsProcess(false);
      setMessage("Invalid inputed dates.");
      setSeverity("error");
      setTimeout(() => {
        setOpenSnackbar(true);
      }, 500);
    }
    if (error.length > 0) {
      console.log("Something went wrong.");
    } else {
      setIsProcess(true);
      axios
        .get("user/merchant/get/expense-filter", { params })
        .then((response) => {
          const data = response.data;
          setOpenFilter(false);
          setIsProcess(false);
          setMessage("Successfully filter record.");
          setSeverity("success");
          setTimeout(() => {
            setOpenSnackbar(true);
            setMerchantExpense({ data, ready: true });
          }, 500);
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("Error: ", error);
        });
    }
  };

  const getMerchantExpense = () => {
    var params = { merchant_id: userData.data.id };
    axios
      .get("user/merchant/get/expense", { params })
      .then((response) => {
        const data = response.data;
        setMerchantExpense({ data, ready: true });
      })
      .catch((err) => console.log("err", err));
  };

  const handleNewExpense = (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.append("status", userData.data.id);
    formdata.append("date_expense", moment(dateExpense).format());
    axios
      .post("user/merchant/create/new-expense", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setIsProcess(false);
          setMessage("Successfully added expense.");
          setSeverity("success");
          getMerchantExpense();
          setExpenseDialog(false);
          setTimeout(() => {
            setOpenSnackbar(true);
          }, 500);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  React.useEffect(() => {
    getMerchantExpense();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100vw",
            maxWidth: "1165px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",

                mx: "15px",
              }}
            >
              <Box>
                <Typography variant="h5">Expenses</Typography>
              </Box>
              <Box flexGrow={1} />
              <Hidden smDown>
                <Box sx={{ mr: 1 }}>
                  <TextField
                    label="Search Expense"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                </Box>
              </Hidden>
              <Box>
                <Tooltip title="Filter">
                  <IconButton
                    onClick={() => {
                      setOpenFilter(true);
                    }}
                  >
                    <TodayIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Add Expense">
                  <IconButton
                    onClick={() => {
                      setExpenseDialog(true);
                    }}
                  >
                    <BookmarkAddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingX: "15px",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchable.length > 0 ? (
                    searchable.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {moment(data.date_expense).format("MMMM DD, YYYY")}
                        </TableCell>
                        <TableCell>{data.expense_name}</TableCell>
                        <TableCell align="right">
                          &#8369;
                          {parseFloat(data.amount).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          <span hidden>{calculate(data.amount)}</span>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Expense Found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={searchable.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mx: "15px",
              }}
            >
              <Box flexGrow={1} />
              {/* <Box flexGrow={1}>{merchantExpense.data.length} record(s) found</Box> */}
              <Box>
                Total: &#8369;
                {`${parseFloat(totalExpenses).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* filter dialog */}
        <Dialog
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          maxWidth="xs"
          fullWidth
        >
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 700 }}>
                  Filter Record
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={() => setOpenFilter(false)}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <DatePicker
                // disableFuture
                label="Date From"
                openTo="year"
                views={["year", "month", "day"]}
                value={dateFrom}
                onChange={(newValue) => {
                  setDateFrom(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <DatePicker
                // disableFuture
                label="Date To"
                openTo="year"
                views={["year", "month", "day"]}
                value={dateTo}
                onChange={(newValue) => {
                  setDateTo(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>

            <Box sx={{ display: "flex", mt: 2 }}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#1976d2",
                  }}
                  onClick={handleFilter}
                  disabled={isProcess}
                >
                  Filter
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>

        {/* expense dialog */}
        <Dialog
          open={expenseDialog}
          onClose={() => setExpenseDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box flexGrow={1}>
                <Typography style={{ fontWeight: 700 }}>Add Expense</Typography>
              </Box>
              <Box>
                <IconButton onClick={() => setExpenseDialog(false)}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box>
              <form onSubmit={handleNewExpense}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                        fontWeight: 700,
                      },
                      "& .MuiInput-input": {
                        borderRadius: "8px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        fontSize: 14,
                      },
                      "& label + .MuiInput-root-emKjRF": {
                        marginTop: "18px",
                      },
                    }}
                    InputProps={{
                      disableunderline: "true",
                      inputProps: {
                        style: {
                          color: "#AEAEAE",
                          backgroundColor: "#fff",
                        },
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Expense Name"
                    fullWidth
                    // size="small"
                    name="expense_name"
                    required
                    multiline
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <DatePicker
                    // disableFuture
                    label="Date Expense"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={dateExpense}
                    onChange={(newValue) => {
                      setDateExpense(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                        fontWeight: 700,
                      },
                      "& .MuiInput-input": {
                        borderRadius: "8px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        fontSize: 14,
                      },
                      "& label + .MuiInput-root-emKjRF": {
                        marginTop: "18px",
                      },
                    }}
                    InputProps={{
                      disableunderline: "true",
                      inputProps: {
                        min: 1,
                        max: 99999999999,
                        step: 0.01,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Amount"
                    fullWidth
                    // size="small"
                    name="amount"
                    required
                    type="number"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor:
                          process.env.REACT_APP_APP_NAME === "estherph"
                            ? "#261138"
                            : "#ff9f1d",
                      }}
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
export default MerchantExpense;
