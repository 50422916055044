import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Check, Close, Edit, Payment } from "@mui/icons-material";
import { UserInfo } from "../../context";
import moment from "moment";

const RentalTransaction = () => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedData, setSelectedData] = useState(null);
  const [collectedDiag, setCollectedDiag] = useState(false);
  const [editDiag, setEditDiag] = useState(false);
  const [paymentDiag, setPaymentDiag] = useState(false);
  const [completeDiag, setCompleteDiag] = useState(false);
  const [rentalTransaction, setRentalTransaction] = useState({
    data: [],
    ready: false,
  });
  const [inputedStatus, setInputedStatus] = useState(null);
  const [inputedDateFrom, setInputedDateFrom] = useState(null);
  const [inputedDateTo, setInputedDateTo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [nonCash, setNonCash] = useState(null);
  const [cashPayment, setCashPayment] = useState(null);
  const [ecashPayment, setEcashPayment] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getRentalTransaction = () => {
    axios
      .get("user/admin/get/rental-transaction")
      .then((response) => {
        const data = response.data;
        setRentalTransaction({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  function getDaysBetween(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in time (in milliseconds)
    const differenceInTime = end.getTime() - start.getTime();

    // Convert the difference in milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
  }

  useEffect(() => {
    getRentalTransaction();
  }, []);

  const handleMarkAsCollected = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", selectedData !== null && selectedData.id);
    setIsProcess(true);
    axios
      .post("user/admin/update/rental-transaction", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully updated rental transaction");
          setSeverity("success");
          setOpenSnackbar(true);

          setCollectedDiag(false);
          setIsProcess(false);
          getRentalTransaction();
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  const handleEditRentalInfo = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", selectedData !== null && selectedData.id);
    formdata.append("date_from", inputedDateFrom);
    formdata.append("date_to", inputedDateTo);
    formdata.append(
      "no_days",
      parseInt(getDaysBetween(inputedDateFrom, inputedDateTo)) + 1
    );
    formdata.append(
      "datetime_retrieval",
      moment(
        `${inputedDateTo} ${moment(
          selectedData !== null && selectedData.datetime_retrieval
        ).format("HH:mm:ss")}`
      ).format("YYYY-MM-DD HH:mm:ss")
    );
    formdata.append(
      "balance_payment",
      parseInt(getDaysBetween(inputedDateFrom, inputedDateTo) + 1) > 1
        ? parseFloat(
            parseInt(getDaysBetween(inputedDateFrom, inputedDateTo) + 1) *
              parseFloat(selectedData.item_cost)
          ) - parseFloat(selectedData.item_cost)
        : 0
    );

    setIsProcess(true);
    axios
      .post("user/admin/update/date-rental-transaction", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully updated the date of rental transaction");
          setSeverity("success");
          setOpenSnackbar(true);

          setEditDiag(false);
          setIsProcess(false);
          getRentalTransaction();
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  const handlePaymentToRentalBal = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", selectedData !== null && selectedData.id);

    setIsProcess(true);
    axios
      .post("user/admin/update/payment-rental-transaction", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully pay the rental transaction");
          setSeverity("success");
          setOpenSnackbar(true);

          setPaymentDiag(false);
          setIsProcess(false);
          getRentalTransaction();
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  const handleCompleteRentalTrnx = () => {
    var formdata = new FormData();
    formdata.append("id", selectedData !== null && selectedData.id);

    setIsProcess(true);
    axios
      .post("user/admin/update/complete-rental-transaction", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully pay the rental transaction");
          setSeverity("success");
          setOpenSnackbar(true);

          setCompleteDiag(false);
          setIsProcess(false);
          getRentalTransaction();
        }
      })
      .catch((error) => {
        setIsProcess(false);
      });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Fullname</b>
              </TableCell>
              <TableCell align="center">
                <b>Item Name</b>
              </TableCell>
              <TableCell align="center">
                <b>No of Days</b>
              </TableCell>
              <TableCell align="center">
                <b>Date Period</b>
              </TableCell>
              <TableCell align="center">
                <b>Datetime Obtained</b>
              </TableCell>
              <TableCell align="center">
                <b>Datetime Retrieval</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rentalTransaction.ready && rentalTransaction.data.length > 0 ? (
              rentalTransaction.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {data.riderinfo.firstname} {data.riderinfo.lastname}
                  </TableCell>
                  <TableCell>{data.item_name}</TableCell>
                  <TableCell align="right">{data.no_days}</TableCell>
                  <TableCell>
                    {moment(data.date_from).format("ll")} -{" "}
                    {moment(data.date_to).format("ll")}
                  </TableCell>
                  <TableCell align="right">
                    {data.datetime_obtain !== null
                      ? moment(data.datetime_obtain).format("lll")
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    {moment(data.datetime_retrieval).format("lll")}
                  </TableCell>
                  <TableCell align="center">
                    {parseInt(data.status) === 0
                      ? "Unit Not Collect"
                      : "Unit Collected"}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Tooltip title="Edit Transaction">
                        <IconButton
                        //   onClick={() => {
                        //     setSelectedTime(
                        //       moment(
                        //         `2023-10-16 ${data.time_retrieval}`
                        //       ).format("HH:mm")
                        //     );
                        //     setSelectedData(data);
                        //     setCollectedDiag(true);
                        //   }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip> */}
                      {parseInt(data.status) === 1 ? (
                        <>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                // console.log("EDIT");
                                setInputedDateFrom(data.date_from);
                                setInputedDateTo(data.date_to);
                                setSelectedData(data);
                                setEditDiag(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>

                          {parseFloat(data.balance_payment) > 0 &&
                          data.balance_payment_status === "PENDING" ? (
                            <Tooltip title="Retrieve & Pay">
                              <IconButton
                                onClick={() => {
                                  // console.log(
                                  //   "UPDATE TO 'STATUS 2', 'balance_payment_status COMPLETE', 'datetime_surrendered datenow' "
                                  // );
                                  setSelectedData(data);
                                  setPaymentDiag(true);
                                }}
                              >
                                <Payment />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Mark as retrieve">
                              <IconButton
                                onClick={() => {
                                  // console.log(
                                  //   "UPDATE TO 'STATUS 2', 'balance_payment_status COMPLETE', 'datetime_surrendered datenow' "
                                  // );
                                  setSelectedData(data);
                                  setCompleteDiag(true);
                                }}
                              >
                                <Check />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ) : parseInt(data.status) === 0 ? (
                        <Tooltip title="Mark as collected">
                          <IconButton
                            onClick={() => {
                              setInputedStatus(data.status);
                              setSelectedData(data);
                              setCollectedDiag(true);
                            }}
                          >
                            <Check />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Retrieved">
                          <IconButton>
                            <Check sx={{ color: "green" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8} style={{ color: "red" }}>
                  No rental unit added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={rentalTransaction.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* COLLECTED DIAG */}
      <Dialog
        open={collectedDiag}
        onClose={() => setCollectedDiag(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Mark as Good</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setCollectedDiag(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {selectedData !== null && (
              <form onSubmit={handleMarkAsCollected}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Status"
                    name="status"
                    required
                    size="small"
                    value={inputedStatus}
                    onChange={(e) => setInputedStatus(e.target.value)}
                  >
                    <option value=""> - Select type - </option>
                    <option value="0">Unit Not Collect</option>
                    <option value="1">Unit Collected</option>
                  </TextField>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor: isProcess ? "#e5e5e5" : "#d65d46",
                      }}
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Dialog>

      {/* EDIT DIALOG */}
      <Dialog
        open={editDiag}
        onClose={() => setEditDiag(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>
                Edit Rental Info
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setEditDiag(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {selectedData !== null && (
              <form onSubmit={handleEditRentalInfo}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    label="Date From"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    value={inputedDateFrom}
                    onChange={(e) => setInputedDateFrom(e.target.value)}
                    disabled={selectedData.datetime_obtain !== null}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    label="Date To"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: inputedDateFrom,
                    }}
                    size="small"
                    value={inputedDateTo}
                    onChange={(e) => setInputedDateTo(e.target.value)}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor: isProcess ? "#e5e5e5" : "#d65d46",
                      }}
                      type="submit"
                      disabled={isProcess}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Dialog>

      {/* PAYMENT DIALOG */}
      <Dialog
        open={paymentDiag}
        onClose={() => setPaymentDiag(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>Payment</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setPaymentDiag(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {selectedData !== null && (
              <form onSubmit={handlePaymentToRentalBal}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    label="Current Balance To Pay"
                    // type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    value={parseFloat(
                      selectedData.balance_payment
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    // onChange={(e) => setInputedDateFrom(e.target.value)}
                    disabled
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Payment Method"
                    name="balance_payment_method"
                    required
                    size="small"
                    value={paymentMethod}
                    onChange={(e) => {
                      setPaymentMethod(e.target.value);
                      setNonCash(null);
                      if (e.target.value === "cash") {
                        setCashPayment(selectedData.balance_payment);
                        setEcashPayment(null);
                      }

                      if (e.target.value === "ecash") {
                        setEcashPayment(selectedData.balance_payment);
                        setCashPayment(null);
                      }

                      if (e.target.value === "split-cash") {
                        setEcashPayment(0);
                        setCashPayment(0);
                      }
                    }}
                  >
                    <option value=""> - </option>
                    <option value="cash">Cash</option>
                    <option value="ecash">E-Cash</option>
                    <option value="split-cash">Split Cash</option>
                  </TextField>
                </Box>

                {paymentMethod !== null && (
                  <>
                    {paymentMethod !== "ecash" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                          mb: 1,
                        }}
                      >
                        <TextField
                          InputProps={{
                            inputProps: {
                              min: 1,
                              max: 99999999999,
                              step: 0.01,
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Cash Amount Paid"
                          fullWidth
                          name="payment_cash"
                          required
                          type="number"
                          size="small"
                          value={cashPayment}
                          onChange={(e) => setCashPayment(e.target.value)}
                        />
                      </Box>
                    )}

                    {paymentMethod !== "cash" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            mb: 1,
                          }}
                        >
                          <TextField
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Non Cash"
                            name="noncash"
                            required
                            size="small"
                            value={nonCash}
                            onChange={(e) => setNonCash(e.target.value)}
                          >
                            <option value=""> - </option>
                            <option value="gcash">GCash</option>
                            <option value="gcredit">GCredit</option>
                            <option value="paymaya">Paymaya</option>
                            <option value="creditcard">Credit Card</option>
                            <option value="debitcard">Debit Card</option>
                            <option value="banktransfer">Bank Transfer</option>
                            <option value="cheque">Cheque</option>
                          </TextField>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            mb: 1,
                          }}
                        >
                          <TextField
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 99999999999,
                                step: 0.01,
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="ECash Amount Paid"
                            fullWidth
                            name="payment_ecash"
                            required
                            type="number"
                            size="small"
                            value={ecashPayment}
                            onChange={(e) => setEcashPayment(e.target.value)}
                            // defaultValue={selectedData.balance_payment}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            mb: 1,
                          }}
                        >
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Ref No."
                            fullWidth
                            name="ref_no"
                            required
                            type="number"
                            size="small"
                            // defaultValue={selectedData.balance_payment}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}

                <Box>
                  <Typography>
                    Total Payment:{" "}
                    {parseFloat(
                      parseFloat(cashPayment !== null ? cashPayment : 0) +
                        parseFloat(ecashPayment !== null ? ecashPayment : 0)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography>
                    Change:{" "}
                    {parseFloat(
                      parseFloat(cashPayment !== null ? cashPayment : 0) +
                        parseFloat(ecashPayment !== null ? ecashPayment : 0) -
                        parseFloat(selectedData.balance_payment)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 2,
                        fontSize: 12,
                        paddingX: 2,
                        backgroundColor:
                          isProcess ||
                          parseFloat(
                            parseFloat(cashPayment !== null ? cashPayment : 0) +
                              parseFloat(
                                ecashPayment !== null ? ecashPayment : 0
                              )
                          ) < selectedData.balance_payment
                            ? "#e5e5e5"
                            : "#d65d46",
                      }}
                      type="submit"
                      disabled={
                        isProcess ||
                        parseFloat(
                          parseFloat(cashPayment !== null ? cashPayment : 0) +
                            parseFloat(ecashPayment !== null ? ecashPayment : 0)
                        ) < selectedData.balance_payment
                      }
                    >
                      Confirm & Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Dialog>

      {/* COMPLETE DIALOG */}
      <Dialog
        open={completeDiag}
        onClose={() => setCompleteDiag(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box
        // sx={{ padding: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // mb: 1,
              pt: 1,
              pl: 2,
              pr: 2,
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <IconButton onClick={() => setCompleteDiag(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          {/* <Divider /> */}
          <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
            {selectedData !== null && (
              <>
                <Typography>
                  Are you sure to complete this transaction?
                </Typography>

                <Box sx={{ display: "flex", mt: 1 }}>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="text"
                      onClick={() => {
                        setCompleteDiag(false);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleCompleteRentalTrnx}
                    >
                      Yes
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default RentalTransaction;
