import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Close, Edit } from "@mui/icons-material";
import { UserInfo } from "../../context";
import cities from "../../../assets/city.json";
import provinces from "../../../assets/province.json";

const CityList = ({ cityList, getCityList }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    React.useContext(UserInfo);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addCityDialog, setAddCityDialog] = React.useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditPerItem = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("id", selectedData.id);
    setIsProcess(true);
    setAddCityDialog(false);
    axios
      .post("user/admin/update/city", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully updated amount per item");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getCityList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  const getCityByCode = (code) => {
    const filtered = cities.filter((city) => city.city_code === code);
    return filtered[0].city_name;
  };

  const getProvinceByCode = (code) => {
    const filtered = provinces.filter((prov) => prov.province_code === code);
    return filtered[0].province_name;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                <b>Province</b>
              </TableCell>
              <TableCell align="center">
                <b>City</b>
              </TableCell>
              <TableCell align="center">
                <b>Per Item</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {cityList.ready && cityList.data.length > 0 ? (
              cityList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{getProvinceByCode(data.province)}</TableCell>
                  <TableCell>{getCityByCode(data.city)}</TableCell>
                  <TableCell align="right">&#8369;{data.per_item}</TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Edit Per Item">
                        <IconButton
                          onClick={() => {
                            setAddCityDialog(true);
                            setSelectedData(data);
                          }}
                          // disabled={module === 'admin' && parseInt(allowAccount.allow) === 0 ? true : false}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No city added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={cityList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={addCityDialog}
        onClose={() => setAddCityDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flexGrow={1}>
              <Typography style={{ fontWeight: 700 }}>
                Edit Amount Per Item
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setAddCityDialog(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {selectedData !== null && (
              <form onSubmit={handleEditPerItem}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Province Name"
                    fullWidth
                    name="province"
                    value={
                      selectedData.province !== null
                        ? getProvinceByCode(selectedData.province)
                        : ""
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="City Name"
                    fullWidth
                    name="city"
                    value={
                      selectedData.city !== null
                        ? getCityByCode(selectedData.city)
                        : ""
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 99999999999,
                        step: 1,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Amount Per Item"
                    fullWidth
                    name="per_item"
                    required
                    type="number"
                    defaultValue={
                      selectedData.per_item !== null
                        ? selectedData.per_item
                        : ""
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      //   size="small"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 2,
                        // fontSize: 12,
                        paddingX: 2,
                        backgroundColor:
                          process.env.REACT_APP_APP_NAME === "estherph"
                            ? "#261138"
                            : "#ff9f1d",
                        color: "#fff",
                      }}
                      type="submit"
                      disabled={isProcess}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CityList;
