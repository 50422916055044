import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { UserInfo } from "../../context";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageViewer from "react-simple-image-viewer";
import provinces from "./../../../assets/province.json";
import cities from "./../../../assets/city.json";
import barangays from "./../../../assets/barangay.json";

const MerchantPayments = ({ allowPayment, module }) => {
  const [paymentList, setPaymentList] = useState({ data: [], ready: false });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    data: null,
  });
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMerchantPayments = () => {
    axios
      .get("user/admin/get/merchant/payments")
      .then((response) => {
        const data = response.data;
        setPaymentList({ data, ready: true });
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleConfirmPayment = () => {
    var formdata = new FormData();
    formdata.append("id", confirmDialog.data && confirmDialog.data.id);
    setIsProcess(true);
    axios
      .post("user/admin/update/merchant/payment", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setTimeout(() => {
            setIsProcess(false);
            setMessage("Successfully confirm the payment.");
            setSeverity("success");
            setConfirmDialog({ ...confirmDialog, open: false });
            getMerchantPayments();
            setTimeout(() => {
              setOpenSnackbar(true);
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    getMerchantPayments();
  }, []);

  const getBarangayByCode = (code) => {
    const filtered = barangays.filter((brgy) => brgy.brgy_code === code);
    return filtered[0].brgy_name;
  };

  const getCityByCode = (code) => {
    const filtered = cities.filter((city) => city.city_code === code);
    return filtered[0].city_name;
  };

  const getProvinceByCode = (code) => {
    const filtered = provinces.filter((prov) => prov.province_code === code);
    return filtered[0].province_name;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Collection ID</b>
              </TableCell>
              <TableCell align="center">
                <b>Business Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Business Address</b>
              </TableCell>
              <TableCell align="center">
                <b>Payment Category</b>
              </TableCell>
              <TableCell align="center">
                <b>Amount</b>
              </TableCell>
              <TableCell align="center">
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentList.ready && paymentList.data.length > 0 ? (
              paymentList.data.map((data, index) => (
                <TableRow key={index}>
                  <TableCell align="right">
                    {data.cp_id.replace("c-", "")}
                  </TableCell>
                  <TableCell>
                    {data.collectionmerchantinfo.business_name}
                  </TableCell>
                  <TableCell>
                    {/* {data.collectionmerchantinfo.street},{" "}
                    {data.collectionmerchantinfo.barangay},{" "}
                    {data.collectionmerchantinfo.city} */}
                    {data.collectionmerchantinfo.street},{" "}
                    {getBarangayByCode(data.collectionmerchantinfo.barangay)},{" "}
                    {getCityByCode(data.collectionmerchantinfo.city)},{" "}
                    {getProvinceByCode(data.collectionmerchantinfo.province)}
                  </TableCell>
                  <TableCell>
                    {data.payment_category === "online"
                      ? `${data.payment_category}(GCash)`
                      : `${data.payment_category}(Pick-up)`}
                  </TableCell>
                  <TableCell align="right">
                    &#8369;
                    {parseFloat(data.amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {data.payment_category === "online" && (
                        <Tooltip title="View Attachment">
                          <AttachFileIcon
                            sx={{ cursor: "pointer", mr: 1 }}
                            onClick={() => {
                              let dataArr = data.payment_attachment.split(",");
                              const res = dataArr.filter(
                                (item) =>
                                  item.includes(".jpg") ||
                                  item.includes(".png") ||
                                  item.includes(".jpeg") ||
                                  item.includes(".JPG") ||
                                  item.includes(".PNG") ||
                                  item.includes(".JPEG") ||
                                  item.includes(".jfif")
                              );
                              setSelectedImages(
                                res.map(
                                  (y) =>
                                    `${process.env.REACT_APP_API_IMAGE}merchant/payments/` +
                                    y
                                )
                              );
                              setIsViewerOpen(true);
                            }}
                          />
                        </Tooltip>
                      )}

                      <Tooltip title="Confirm Payment">
                        <IconButton
                          onClick={() => {
                            setConfirmDialog({ open: true, data });
                          }}
                          disabled={
                            module === "admin" &&
                            parseInt(allowPayment.allow) === 0
                              ? true
                              : false
                          }
                        >
                          <ThumbUpOffAltIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6} style={{ color: "red" }}>
                  No merchant payment added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={paymentList.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={confirmDialog.open}
        fullWidth
        maxWidth="xs"
        onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      >
        <Box sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box>
                <Typography>
                  Are you sure to confirm this merchant payment with a <br />
                  collection ID of{" "}
                  {confirmDialog.data &&
                    confirmDialog.data.cp_id.replace("c-", "")}
                  ?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Box flexGrow={1} />
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      backgroundColor: "#d65d46",
                      width: "40%",
                    }}
                    onClick={handleConfirmPayment}
                    disabled={isProcess}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 2,
                      fontSize: 12,
                      backgroundColor: "#AEAEAE",
                      width: "40%",
                      ml: 2,
                    }}
                    onClick={() =>
                      setConfirmDialog({ ...confirmDialog, open: false })
                    }
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      {isViewerOpen && (
        <ImageViewer
          src={selectedImages}
          currentIndex={0}
          onClose={() => setIsViewerOpen(false)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Box>
  );
};

export default MerchantPayments;
